import React from 'react';
import {
    ChildrenContainer,
    Container,
    Required,
    Title
} from './styled';
import {
    any,
    bool,
    string
} from 'prop-types';

const FormInput = ({
    children,
    required,
    title
}) => (
    <Container>
        <Title>
            { title }
            { required && <Required>*</Required> }
        </Title>
        <ChildrenContainer className="no-spinner">
            { children }
        </ChildrenContainer>
    </Container>
);

FormInput.propTypes = {
    children: any,
    required: bool,
    title: string
};

export default FormInput;
