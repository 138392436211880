import { Formik } from 'formik';
import cookie from 'react-cookies';
import * as Yup from 'yup';
import { Button, Card, CardBody, CardGroup, Col, Container, FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap';
import React, { Component } from 'react';
import { putChangePassword } from 'core/User/services';

const validationSchema = function (values) {
  return Yup.object().shape({
    pass: Yup.string()
      .required('Ingresa una contraseña'),
    passRepeat: Yup.string()
      .oneOf([Yup.ref('pass'), null], 'Las contraseñas deben coincidir')
      .required('')
  })
}

const validate = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values)
    try {
      validationSchema.validateSync(values, {
        abortEarly: false
      })
      return {}
    } catch (error) {
      return getErrorsFromValidationError(error)
    }
  }
}

const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    }
  }, {})
}

class Recover extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            error: ''
        }
    }

    UNSAFE_componentWillMount = () => {
        if (!this.props.location.auth || !this.props.location.auth.mail || !this.props.location.auth.activate){
            this.props.history.push('/');            
        }       
    }

    changePass = async (values) => {
        try{
            this.setState({
                loading: true
            })
            let { mail, activate } = this.props.location.auth;
            let { pass } = values;
            const { token } = await putChangePassword({ activate, mail, pass });
            cookie.save('nutricion-auth', token, { path: '/' });
            this.props.history.push('/');
        } catch (err) {
            switch (err.response.status) {
                case 403: {
                    this.setState({
                        error: 'El link de cambio de contraseña ya no es válido',
                        loading: false,
                        errorStatus: 403
                    })
                    break
                }
                default: {

                }
            }
        }
    }

    render() {
        let {loading, error} = this.state;
        let mail = this.props.location.auth ? this.props.location.auth.mail : '';
        return (
            <div className='app flex-row align-items-center'>
                <Formik
                        validate={validate(validationSchema)}
                        onSubmit={this.changePass}
                        initialValues={{
                            surname: '',
                            name: '',
                            email: '',
                            pass: '',
                            passRepeat: ''
                        }}
                        render={({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isValid,
                        }) => (
                                <Container>
                                    <Row className='justify-content-center'>
                                        <Col md='6'>
                                            <CardGroup>
                                                <Card className='p-4'>
                                                    <CardBody>
                                                        <h1 style={{ marginBottom: 30 }}>Ingresa una nueva contraseña</h1>
                                                        <InputGroup className='mb-3'>
                                                            <InputGroupAddon addonType='prepend'>
                                                                <InputGroupText>
                                                                    <i className='icon-user'></i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input type='text' name='email' value={mail} readOnly/>
                                                        </InputGroup>
                                                        <InputGroup className='mb-3'>
                                                            <InputGroupAddon addonType='prepend'>
                                                                <InputGroupText>
                                                                    <i className='icon-lock'></i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input type='password'
                                                                name='pass'
                                                                id='pass'
                                                                placeholder='Contraseña'
                                                                invalid={touched.pass && !!errors.pass}
                                                                required
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.pass} />
                                                            <FormFeedback>{errors.pass}</FormFeedback>
                                                        </InputGroup>
                                                        <InputGroup className='mb-4'>
                                                            <InputGroupAddon addonType='prepend'>
                                                                <InputGroupText>
                                                                    <i className='icon-lock'></i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input type='password'
                                                                name='passRepeat'
                                                                id='passRepeat'
                                                                placeholder='Repetir contraseña'
                                                                invalid={touched.passRepeat && !!errors.passRepeat}
                                                                required
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.passRepeat} />
                                                            {
                                                                values.passRepeat.length > 0 &&
                                                                <FormFeedback>{errors.passRepeat}</FormFeedback>    
                                                            }
                                                        </InputGroup>
                                                        {
                                                            error.length > 0 &&
                                                            <Label className='label-muted'>{error}</Label>
                                                        }                                                        
                                                        <InputGroup className = 'mb-3' >
                                                            <Button color={error.length > 0 ? 'danger' : 'success'} className='px-4' block onClick={handleSubmit} disabled={loading}>
                                                                Cambiar contraseña
                                                            </Button>
                                                        </InputGroup>       
                                                    </CardBody>
                                                </Card>
                                            </CardGroup>
                                        </Col>
                                    </Row>
                                </Container>
                        )}/>
            </div>
        );
    }
}

export default Recover;
