import React, { Fragment } from 'react';
import { Item, Subtitle, Text } from './styled';

export const getContextualInfo = pathname => {
    switch(pathname) {
    case '/calendario': return (
        <Fragment>
            <Subtitle>Calendario</Subtitle>
            <Text>Dentro del calendario podés gestionar todas tus visitas, viendo además las pendientes y canceladas.</Text>
            <Text>Al presionar el botón de "Crear nueva visita", te pediremos los datos para guardar la misma.</Text>
            <Text>Al seleccionar una visita en el calendario, podés reprogramarla, marcarla como cancelada y más.</Text>
            <Text>Podés ver más información desde el botón de "Ayuda" debajo del calendario.</Text>
            <Item>Tips</Item>
            <Text>Desde una computadora, al hacer click directamente sobre el espacio disponible en el calendario, ya cargaremos la fecha y la hora para que sea más fácil crearla.</Text>
        </Fragment>
    );
    case '/configuracion': return (
        <Fragment>
            <Subtitle>Configuración</Subtitle>
            <Text>En esta sección podrás modificar algunos parámetros de configuración de la plataforma para hacerla más personal.</Text>
            <Item>Datos personales</Item>
            <Text>Son tus datos básicos identificatorios. El "Nombre Profesional" es cómo mostraremos tu nombre en los informes para tus pacientes.</Text>
            <Text>Si conocés a alguien que utilice Bamboo, ingresá su mail en "Cuenta Referida" para brindarle días gratis cada vez que realices un pago.</Text>
            <Item>Turnos</Item>
            <Text>Configuraciones por defecto de la gestión de turnos.</Text>
            <Text>Las plantillas de recordatorio es cómo aparecerán los mensajes precargados cuando uses las funciones de avisos por WhatsApp.</Text>
            <Item>Objetivos personales</Item>
            <Text>Podés fijar tus metas mensuales y revisar los resultados en la sección de "Estadísticas".</Text>
        </Fragment>
    );
    case '/contacto': return (
        <Fragment>
            <Subtitle>Contacto</Subtitle>
            <Text>Una vez que recibamos tu consulta, te responderemos vía email lo antes posible.</Text>
            <Text>Si necesitás que te respondamos más rápido, o hablar con nosotros, podés hacerlo a través de nuestro Instagram (bamboo.nutricion)</Text>
        </Fragment>
    );
    case '/cuestionarios': return (
        <Fragment>
            <Subtitle>Cuestionarios</Subtitle>
            <Text>Los cuestionarios son series de preguntas listas para realizarles a tus pacientes, desde su ficha de análisis.</Text>
            <Text>En esta sección, podrás ver el listado de cuestionarios disponibles en la plataforma, para que les realices a tus pacientes.</Text>
            <Text>¡Podés crear nuevos y editarlos a tu gusto!</Text>
            <Item>Tips</Item>
            <Text>Desde una computadora, es posible acceder al tutorial paso a paso para la creación de cuestionarios, con el botón "Aprendé a armarlos".</Text>
            <Text>
                Podés compartir tus cuestionarios con otros colegas con el botón de "Exportar" al seleccionar un cuestionario. Descargarás un archivo .bamboo que un colega
                puede cargar en su cuenta con el botón "Importar".
            </Text>
        </Fragment>
    );
    case '/cuestionarios/editar':
    case '/cuestionarios/nuevo': return (
        <Fragment>
            <Subtitle>Cuestionario</Subtitle>
            <Text>Para aprender a armar los cuestionarios podés ingresar al tutorial desde una computadora en el listado de cuestionarios.</Text>
            <Text>Una vez que armes una plantilla, estará disponible para realizársela a tus pacientes dentro de sus fichas de análisis.</Text>
            <Text>Hay varios tipos de preguntas y tamaños de respuesta, para que puedas armar el cuestionario a tu medida.</Text>
            <Item>Texto simple</Item>
            <Text>Las preguntas de texto permiten ingresar cualquier tipo de texto.</Text>
            <Item>Bloque de texto</Item>
            <Text>Los bloques de texto permiten ingresar mayores cantidades de texto y dividirlo en varios renglones.</Text>
            <Item>Selector</Item>
            <Text>Los selectores permiten elegir una de entre varias opciones agregadas a la pregunta.</Text>
            <Item>Lista de ítems</Item>
            <Text>Las listas de ítems permiten tildar elementos agregados a la pregunta.</Text>
            <Item>Fecha</Item>
            <Text>Las preguntas de fecha despliegan un calendario para seleccionar una fecha específica.</Text>
            <Item>Numérica</Item>
            <Text>Las preguntas numéricas sólo permiten ingresar números.</Text>
        </Fragment>
    );
    case '/cuestionarios/tutorial': return (
        <Fragment>
            <Subtitle>Tutorial</Subtitle>
            <Text>Este tutorial te permitirá aprender cómo realizar cuestionarios para luego aplicarlos a tus pacientes.</Text>
            <Text>Recordá que si te siguen quedando dudas nos podes contactar sin problemas.</Text>
        </Fragment>
    );
    case '/estadisticas': return (
        <Fragment>
            <Subtitle>Estadísticas</Subtitle>
            <Text>En esta sección encontrarás de manera resumida, información relevante de tu actividad en la plataforma.</Text>
            <Item>Objetivos personales</Item>
            <Text>Podés configurar tus objetivos desde el menú de "Configuración" para ver tu progreso en esta pestañá.</Text>
            <Item>Pacientes</Item>
            <Text>Información de tus pacientes atendidos. Si un paciente tiene más de una visita en el mes, no aparecerá repetido.</Text>
            <Item>Ingresos</Item>
            <Text>Datos de ingresos cargados en el campo de "Ingresos" de cada una de tus visitas.</Text>
            <Item>Visitas</Item>
            <Text>Cantidad de visitas registradas mes a mes, discriminadas por sexo.</Text>
            <Item>Cancelaciones</Item>
            <Text>Visitas marcadas como canceladas desde el Calendario.</Text>
            <Item>Edades</Item>
            <Text>Cantidad de visitas registradas mes a mes, discriminadas por rangos etarios.</Text>
        </Fragment>
    );
    case '/historial/visitas': return (
        <Fragment>
            <Subtitle>Historial</Subtitle>
            <Text>En esta sección encontrarás el listado de todas tus visitas registradas.</Text>
            <Text>
                Podés filtrar las visitas seleccionando un paciente en particular, un rango de fechas, y/o pacientes pertenecientes a determinadas etiquetas, seleccionando
                los filtros y luego presionando el botón "Filtrar".
            </Text>
        </Fragment>
    );
    case '/info': return (
        <Fragment>
            <Subtitle>Acerca de</Subtitle>
            <Text>En esta sección verás la evolución del sistema con las diferentes actualizaciones que vamos incorporando.</Text>
            <Text>Cada vez que actualizamos Bamboo, podés ingresar para ver las últimas novedades.</Text>
        </Fragment>
    );
    case '/pacientes': return (
        <Fragment>
            <Subtitle>Pacientes</Subtitle>
            <Text>En esta sección se encuentra el listado de todos tus pacientes cargados en la plataforma.</Text>
            <Text>Para crear un nuevo paciente, presioná el botón "Agregar nuevo paciente".</Text>
            <Text>Podés ingresar a la historia clínica de un paciente en particular seleccionándolo y luego presionando el botón "Ficha de análisis" debajo del listado.</Text>
            <Text>Para editar un paciente, seleccionalo y luego presiona "Editar datos" debajo del listado.</Text>
            <Text>
                Podés desactivar pacientes con el botón correspondiente para dejar de verlos en el listado principal. Para ver aquellos desactivados, cambiá el modo
                "Mostrando pacientes activos" por "Mostrando todos los pacientes" con el botón correspondiente.
            </Text>
        </Fragment>
    );
    case '/pacientes/editar': return (
        <Fragment>
            <Subtitle>Editar paciente</Subtitle>
            <Text>Cargá los datos personales del paciente que quieras editar.</Text>
            <Text>Los campos marcados con asteriscos rojos, son obligatorios.</Text>
            <Text>Recordá presionar el botón "Guardar" una vez que finalices.</Text>
            <Item>Etiquetas</Item>
            <Text>Las etiquetas te permiten agrupar a los pacientes en diferentes categorías. Patologías, localidades, ¡utilizalas como quieras!</Text>
            <Item>WhatsApp</Item>
            <Text>Si cargás correctamente el número de celular, podés acceder a las funciones de plantillas de WhatsApp.</Text>
        </Fragment>
    );
    case '/pacientes/nuevo': return (
        <Fragment>
            <Subtitle>Nuevo paciente</Subtitle>
            <Text>Cargá los datos personales del paciente que quieras agregar.</Text>
            <Text>Los campos marcados con asteriscos rojos, son obligatorios.</Text>
            <Text>
                En esta sección no tenés que ingresar datos de las visitas en particular. Con ese objetivo se encuentra la "Ficha de análisis" desde
                el listado de pacientes.
            </Text>
            <Text>Recordá presionar el botón "Guardar" una vez que finalices.</Text>
            <Item>Etiquetas</Item>
            <Text>Las etiquetas te permiten agrupar a los pacientes en diferentes categorías. Patologías, localidades, ¡utilizalas como quieras!</Text>
            <Item>WhatsApp</Item>
            <Text>Si cargás correctamente el número de celular, podés acceder a las funciones de plantillas de WhatsApp.</Text>
        </Fragment>
    );
    case '/pacientes/general': return (
        <Fragment>
            <Subtitle>Ficha de análisis</Subtitle>
            <Text>Aquí podrás ver toda la información asociada a un paciente en particular.</Text>
            <Text>Podés crear una nueva visita para un paciente con el botón de "Nueva visita", debajo de la sección "Visitas".</Text>
            <Text>
                Para completar un cuestionario para un paciente, presiona el botón de "Nuevo cuestionario", debajo de la sección "Cuestionarios".
                Para más información acerca de cómo crear tus propios cuestionarios, ingresá al menú "Cuestionarios".
            </Text>
            <Text>
                La sección de objetivos te permite cargar objetivos específicos para que cumpla el paciente, y marcarlos cuando se encuentren realizados.
                Podés agregar más detalles a los mismos con el botón del ojo ubicado a la derecha de cada uno.
            </Text>
            <Text>
                La sección de archivos permite subir estudios, fotos, y cualquier documento, para que sea accesible desde cualquier dispositivo que inicies sesión.
            </Text>
        </Fragment>
    );
    case '/pacientes/general/visita': return (
        <Fragment>
            <Subtitle>Visita</Subtitle>
            <Text>Aquí podrás cargar toda la información asociada a una visita de un paciente. Recordá presionar el botón "Guardar" una vez que finalices.</Text>
            <Item>Datos generales</Item>
            <Text>Podrés ingresar la información básica de la visita corriente y compararla con las anteriores. Además, podés usar el botón de cálculo de VCT si lo necesitás.</Text>
            <Item>Antropometría</Item>
            <Text>Están disponibles los campos para completar las mediciones de una antropometría ISAK I. Algunos cálculos se realizan automáticamente debajo.</Text>
            <Item>Somatotipo</Item>
            <Text>Automáticamente toma los datos cargados en la pestaña de Datos Generales y de Antropometría, y grafica la somatocarta.</Text>
            <Item>Niños y adolescentes</Item>
            <Text>Sólo disponible para pacientes menores de edad, muestra automáticamente las curvas de crecimiento, en base a los datos cargados en las otras pestañas.</Text>
            <Item>Embarazo</Item>
            <Text>Muestra automáticamente la curva de IMC para embarazadas ingresando la semana de gestación.</Text>
            <Item>Impresión para pacientes</Item>
            <Text>
                Prepara automáticamente un reporte para el paciente, que puede ser impreso o guardado como un archivo PDF para enviar por algún medio digital.
                Podés seleccionar el contenido del reporte con los diferentes botones que aparecen.
            </Text>
            <Text>Para guardar el archivo como PDF, una vez que presiones "Imprimir", debés seleccionar la opción "Guardar como PDF"</Text>
        </Fragment>
    );
    case '/pacientes/general/cuestionario': return (
        <Fragment>
            <Subtitle>Cuestionario</Subtitle>
            <Text>En esta sección podrás realizarle un cuestionario a un paciente.</Text>
            <Text>Al presionar el botón "Guardar" podrás verlo en el listado correspondiente de la ficha de análisis.</Text>
            <Item>Herramientas</Item>
            <Text>
                Sobre la derecha (cuando este panel no está visible), se encuentra el botón de "Herramientas".
                El pincel permite resaltar las preguntas para luego prestarle más atención, mientras que
                el globo de texto, permite agregar comentarios adicionales en cada una.
            </Text>
            <Item>Nuevo cuestionario</Item>
            <Text>Podés elegir un cuestionario del listado desplegable. Para crear o editar estos cuestionarios, tenés que ingresar al menú "Cuestionarios"</Text>
            <Item>Editar cuestionario</Item>
            <Text>
                Al editar un cuestionario anterior, podés presionar el botón a la izquierda de "Editar cuestionario existente" para guardar la información en un nuevo
                cuestionario y no perder las respuestas anteriores.
                No olvides que si guardás en un nuevo cuestionario, podés cambiar la fecha de realización para tener un seguimiento de los mismos.
            </Text>
        </Fragment>
    );
    case '/pagos': return (
        <Fragment>
            <Subtitle>Pagos</Subtitle>
            <Text>En esta sección podrás ver el listado de todos tus pagos realizados en la plataforma.</Text>
            <Text>Podés ver más información con los botones de "Preguntas frecuentes" y de "Obtené descuentos".</Text>
            <Text>Cualquier otra consulta, problema o inquietud, no dudes en contactarnos.</Text>
            <Item>Convenios</Item>
            <Text>
                Si aplicás para un convenio en particular, tenés que seleccionarlo del menú desplegable, completar los datos, y precionar el botón de "Solicitar".
                Una vez que lo validemos, te avisaremos, y tendrás acceso a descuentos en todos tus pagos.
            </Text>
            <Text>No es necesario que solicites el convenio más de una vez.</Text>
        </Fragment>
    );
    case '/plantillas': return (
        <Fragment>
            <Subtitle>Plantillas</Subtitle>
            <Text>Las plantillas permiten prediseñar mensajes para enviar por WhatsApp a tus pacientes desde el listado, en el menú "Pacientes".</Text>
            <Text>Podrás escribirlas de una manera especial que permite reemplazar información automáticamente cuando los envíes.</Text>
        </Fragment>
    );
    default: return (
        <Fragment>
            <Text textAlign="center">¡Bienvenido a Bamboo Nutrición!</Text>
            <Text>En este panel vas a encontrar la ayuda correspondiente a la sección de la plataforma donde te encuentres.</Text>
            <Text>
                Contamos con dos menús para navegar por toda la funcionalidad de Bamboo. El primero se encuentra sobre la izquierda, activable con el botón ubicado en la esquina
                superior izquierda de la pantalla. El segundo, es el de "Perfil", y se activa con el botón correspondiente en la esquina superior derecha.
            </Text>
            <Text>
                Si es tu primera vez utilizando la plataforma, te recomendamos ingresar a la sección de "Pacientes" para comenzar a cargar sus datos.
            </Text>
            <Text>Si tenés dudas o sugerencias, no dudes en enviarnos un correo desde la sección de Perfil - Contacto, o hablarnos directamente a nuestro Instagram.</Text>
            <Item>Tips</Item>
            <Text>
                Podés utilizar Bamboo como si fuera una aplicación desde tu dispositivo móvil. Para hacerlo, abrí esta página en el dispositivo.
                Una vez dentro, presioná el menú del navegador, y elegí la opción "Agregar a la pantalla principal". ¡Y listo!
            </Text>
        </Fragment>
    );
    }
};
