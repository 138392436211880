import { getPatientGroups } from 'core/Patient/services';
import Select from 'react-select';
import 'react-select/dist/react-select.min.css';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
    array,
    func,
    string
} from 'prop-types';

const PatientGroupSelect = forwardRef(({
    onChange,
    placeholder,
    value
}, ref) => {

    const [patientGroups, setPatientGroups] = useState([]);

    useEffect(() => {
        loadGroups();
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            refreshGroups() {
                loadGroups();
            }
        }),
    );

    const onSelectChange = selected => {
        const values = selected.map(({ value }) => value);
        onChange(values);
    };

    const loadGroups = async () => {
        const data = await getPatientGroups();
        const parsedData = data.map(({ description, id }) => ({
            label: description,
            value: id
        }));
        setPatientGroups(parsedData);
    };

    return (
        <Select
            multi
            color='green'
            options={ patientGroups }
            noResultsText='No hay etiquetas disponibles'
            placeholder= { placeholder ?? 'Seleccioná etiquetas para clasificar al paciente' }
            value={ value }
            onBlurResetsInput={ false }
            onChange={ onSelectChange }
            onCloseResetsInput={ false }
            onSelectResetsInput={ false }
        />
    );
});

PatientGroupSelect.propTypes = {
    onChange: func,
    placeholder: string,
    value: array
};

export default PatientGroupSelect;
