import { Alert } from 'reactstrap';
import React, { Component } from 'react';

export default class ExperimentalWarning extends Component {
    render() {
        return (
            <Alert color='danger' style={{ borderColor: 'white', borderWidth: 2, textAlign: 'center' }}>
                Esta sección todavía está siendo validada por nuestro equipo. Si además de lo que ves considerás agregar más funcionalidades, comunicate con nosotros!
            </Alert>
        )
    }
}
