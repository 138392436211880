import { getPatientNames } from 'core/Patient/services';
import { Input } from 'reactstrap';
import React, { Component, Fragment } from 'react';
import { bool, func, number, string } from 'prop-types';

class PatientPicker extends Component {

    static propTypes = {
        name: string,
        onChange: func,
        placeholder: string,
        selected: number,
        selectNone: bool,
        size: string
    }
    
    constructor(props) {
        super(props);
        this.state = {
            patients: []
        }
    }

    async componentDidMount(){
        try{
            const patients = await getPatientNames();
            this.setState({
                patients
            })
        } catch (err) {
            
        }
    }

    onChange = event => {
        const { onChange } = this.props;
        const { patients } = this.state;
        const { target: { value } } = event;
        const patient = patients.find( (elem) => elem.id === parseInt(value, 10));
        onChange(event, patient);
    }

    render() {
        let { name, placeholder, selected, selectNone, size } = this.props;
        return (
            <Fragment>
                <Input type='select' bsSize={size || 'md'} name={name} value={selected} onChange={(event)=>this.onChange(event)}>
                    {
                        ((selected === 0) || selectNone) &&
                        <option value={0}>{ placeholder || 'Seleccioná un paciente...' }</option>
                    }
                    {
                        this.state.patients.map( patient => 
                            <option key={patient.id} value={patient.id}>{patient.surname + ', ' + patient.name}</option>
                        )
                    }
                </Input>
            </Fragment>

        );
    }
}

export default PatientPicker;