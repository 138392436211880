import {
    axiosDelete,
    axiosGet,
    axiosPost,
    axiosPut
} from 'core/axiosInstance';

export const deleteWhatsappTemplate = async id => {
    try {
        await axiosDelete(`whatsapp-template/${ id }`);
        return true;
    } catch (err) {
        return false;
    }
}

export const getWhatsappTemplate = async () => {
    try {
        const res = await axiosGet('whatsapp-template');
        return !!res ? res.data : [];
    } catch (err) {
        return [];
    }
}

export const postWhatsappTemplate = async body => {
    try {
        await axiosPost('whatsapp-template', body);
        return true;
    } catch (err) {
        return false;
    }
}

export const putWhatsappTemplate = async (id, body) => {
    try {
        await axiosPut(`whatsapp-template/${ id }`, body);
        return true;
    } catch (err) {
        return false;
    }    
}
