import { Icon } from 'assets/img';
import cookie from 'react-cookies';
import { Alert, Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap';
import React, { Component } from 'react';
import { getReactivateUser, getRecoverPassword, getVerify, postLogin, putActivateUser } from 'core/User/services';
const queryString = require('query-string-es5');

class Login extends Component {
    constructor() {
        super();
        this.state = {
            user: {
                email: '',
                pass: '',
            },
            error: '',
            errorStatus: 0,
            resentMail: '',
            recoveredPass: false,
            resentActivation: false,
            loading: false,
            activated: false
        }
    }

    componentDidMount = async () => {
        const { location: { search } } = this.props;
        if (!!search && !queryString.parse(search).next) {
            try {
                const { mode, mail, activate } = queryString.parse(search);
                if (mode === 'recover') {
                    this.props.history.push({
                        pathname: '/recuperar',
                        auth: {
                            mail,
                            activate
                        }
                    })
                }
                else if (mode === 'activate') {
                    await putActivateUser({ activate, mail });
                    this.setState({
                        activated: true
                    })
                    this.props.history.replace('/login')
                }
            } catch (err) {
                this.props.history.push('/');
            }
        }
        else {
            let token = cookie.load('nutricion-auth');
            if (!token) {
                token = localStorage.getItem('nutricion-auth');
            }
            if (token) {
                cookie.save('nutricion-auth', token, { path: '/' });
                try {
                    const res = await getVerify();
                    if (res && res.status === 200) {
                        this.props.history.push(`/`);
                    }
                } catch (err) {

                }
            }
        }
    }

    handleUserInput = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let user = this.state.user;
        user[name] = value;
        this.setState({
            error: '',
            user
        });
    }

    login = async () => {
        const { user } = this.state;
        try {
            const { email, pass } = user;
            if (!!email && !!pass) {
                this.setState({
                    loading: true
                });
                const { token } = await postLogin(user);
                cookie.save('nutricion-auth',token, { path: '/' });
                const { next } = queryString.parse(this.props.location.search);
                this.props.history.push(`/${ !!next ? next : '' }`);
            } else {
                this.setState({
                    error: 'Ingresá tu mail y tu contraseña',
                    loading: false,
                    errorStatus: 0,
                    resentMail: email
                });
            }
        } catch (err) {
            switch (err.response.status) {
                case 403: {
                    this.setState({
                        error: 'Los datos ingresados son incorrectos',
                        loading: false,
                        errorStatus: 403,
                        resentMail: user.email
                    })
                    break
                }
                case 401: {
                    this.setState({
                        error: 'La cuenta no está habilitada',
                        loading: false,
                        errorStatus: 401,
                        resentMail: user.email
                    })
                    break
                }
                default: {
                    this.setState({
                        error: 'Los datos ingresados son incorrectos',
                        loading: false,
                        errorStatus: 500,
                        resentMail: user.email
                    })
                    break
                }
            }
        }
    }

    recoverPass = async () => {
        let { email } = this.state.user;
        if (email.length > 0) {
            try {
                this.setState({
                    loading: true
                })
                await getRecoverPassword({ mail: encodeURI(email) });
                this.setState({
                    loading: false,
                    recoveredPass: true
                })
            } catch (err) {
                this.setState({
                    error: 'Ingresá correctamente el mail de tu cuenta al que podamos enviarte la contraseña',
                    loading: false
                })
            }
        }
    }

    resendActivation = async () => {
        try {
            let { resentMail } = this.state;
            this.setState({
                loading: true
            });
            await getReactivateUser({ mail: encodeURI(resentMail) });
            this.setState({
                loading: false,
                resentActivation: true,
                error: ''
            })
        } catch (err) {
            this.setState({
                loading: false
            })
        }
    }

    onKeyPressAccept = (target) => {
        if (target.charCode === 13) {
            this.login();
        }
    }

    register = () => {
        this.props.history.push({
            pathname: './registro'
        })
    }

    render() {
        let { activated, error, errorStatus, loading, recoveredPass, resentActivation, user } = this.state;
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="6" md="8">
                            <CardGroup>
                                <Card className="p-4" style={{ backgroundColor: 'transparent' }}>
                                    <CardBody className="login-card-body">
                                        <div style={{
                                            backgroundColor: 'white',
                                            position: 'absolute',
                                            top: 0,
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            width: '100%',
                                            height: '100%',
                                            zIndex: -2
                                        }} />
                                        <div style={{ 
                                            backgroundImage: `url(${Icon})`, 
                                            backgroundRepeat: 'no-repeat', 
                                            backgroundPosition: 'center', 
                                            opacity: .2,
                                            position: 'absolute',
                                            top: 0,
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            width: '100%',
                                            height: '100%',
                                            zIndex: -1
                                        }}/>
                                        <Label className="login-title">
                                            ¡Ingresá con tu cuenta!
                                        </Label>
                                        {
                                            activated &&
                                            <Alert color='success' style={{ borderColor: 'white', borderWidth: 2, textAlign: 'center' }}>
                                                Activamos tu cuenta correctamente
                                            </Alert>
                                        }
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="icon-user"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="text" placeholder="Email" name='email' value={user.email} onChange={(e) => this.handleUserInput(e)} onKeyPress={this.onKeyPressAccept} />
                                        </InputGroup>
                                        <InputGroup className="mb-4">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="icon-lock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="password" placeholder="Contraseña" name='pass' value={user.pass}
                                                onKeyPress={this.onKeyPressAccept} onChange={(e) => this.handleUserInput(e)} />
                                        </InputGroup>
                                        {
                                            error && error.length > 0 && (errorStatus === 401) ?
                                                <Label style={{ color: 'red', fontStyle: 'italic' }}>
                                                    {this.state.error}.
                                                    Para reenviar el correo presione
                                                    <Button color='link' size='sm' onClick={this.resendActivation} style={{
                                                        fontStyle: 'italic', padding: 0, marginBottom: 4, marginLeft: 3,
                                                        fontSize: 14, textDecoration: 'underline', zIndex: 1
                                                    }}>
                                                        aquí
                                                    </Button>
                                                </Label>
                                                :
                                                <Label style={{ color: 'red', fontStyle: 'italic' }}>{this.state.error}</Label>
                                        }
                                        {
                                            resentActivation && error.length === 0 &&
                                            <InputGroup className="mb-4" style={{ marginTop: -20 }}>
                                                <Label style={{ textAlign: 'justify' }}>
                                                    Enviamos un correo al mail ingresado para activar la cuenta. Si no lo encontrás, por favor revisá el correo no deseado.
                                                </Label>
                                            </InputGroup>
                                        }
                                        <InputGroup className="mb-3" >
                                            <Button color="success" className="px-4" block onClick={() => this.login()} disabled={loading}>
                                                Ingresar
                                            </Button>
                                        </InputGroup>
                                        <Row style={{ alignItems: 'center' }}>
                                            <Col xs='12' md='6'>
                                                <Button size="lg" onClick={this.register} color='link' style={{ color: '#307548', fontSize: 20, marginLeft: -9,
                                                    cursor: 'pointer', padding: 0, textDecoration: 'none' }} disabled={loading}>
                                                    ¡No tengo una cuenta!
                                                </Button>
                                            </Col>
                                            <Col xs='12' md='6' className='text-center text-md-right'>
                                                {
                                                    !recoveredPass &&
                                                    <Button onClick={this.recoverPass} color='link' style={{
                                                        color: '#307548', marginLeft: -9, cursor: 'pointer',
                                                        paddingLeft: 15, paddingTop: 15, paddingRight: 0
                                                    }}
                                                        disabled={loading || user.email.length === 0}>
                                                        Olvidé mi contraseña
                                                        </Button>
                                                }
                                            </Col>
                                        </Row>
                                        {
                                            recoveredPass &&
                                            <InputGroup className="mb-4">
                                                <Label style={{ textAlign: 'justify' }}>
                                                    Enviamos un correo al mail ingresado con las instrucciones para recuperar la contraseña.
                                                    Si no lo encontrás, por favor revisá el correo no deseado.
                                                    </Label>
                                            </InputGroup>
                                        }
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Login;
