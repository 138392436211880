import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import moment from 'moment';
import 'moment/locale/es';
import { registerLocale } from  "react-datepicker";
import React, { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
registerLocale('es', es)

const DatePicker = ({
    onChange,
    value
}) => {

    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        setSelectedDate(value ? value.toDate() : null);
    }, [value]);

    const customChange = newDate => {
        onChange && onChange(moment(newDate));
    };

    return (
        <ReactDatePicker
            scrollableYearDropdown
            showYearDropdown
            className="form-control text-center"
            dateFormat="dd/MM/yyyy"
            dateFormatCalendar="MMMM"
            locale="es"
            maxDate={ new Date() }
            selected={ selectedDate }
            yearDropdownItemNumber={ 70 }
            onChange={ customChange }
        />
    );
};

DatePicker.propTypes = {
    onChange: func,
    value: object
};

export default DatePicker;