import styled from 'styled-components';
import {
    CardHeader as CardHeader_,
    Row as Row_
} from 'reactstrap';

export const CardHeader = styled(CardHeader_)`
    overflow-x: auto;
    padding: 5px;
`;

export const Row = styled(Row_)`
    margin: 0px;
    ${ ({ minwidth }) => minwidth ? `min-width:${ minwidth }` : '' }
`;
