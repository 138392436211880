import { Col } from './styled';
import React from 'react';
import { Row } from 'reactstrap';
import moment from 'moment';
import { parseAge } from 'common/functions';
import { FormInput, Modal } from 'components';
import { bool, func, object } from 'prop-types';

const PatientModal = ({
    active,
    data,
    toggle
}) => {

    const {
        address,
        borndate,
        cellphone,
        dni,
        email,
        gender,
        health_insurance,
        health_insurance_number,
        name,
        nickname,
        notes,
        phone,
        surname
    } = data;

    const parsedData = [{
        text: borndate ? moment(borndate).format('DD/MM/YYYY') : null,
        title: 'Fecha de nacimiento'
    }, {
        text: borndate ? parseAge(moment(borndate)) : null,
        title: 'Edad'
    }, {
        text: nickname,
        title: 'Apodo'
    }, {
        text: dni,
        title: 'DNI'
    }, {
        text: cellphone,
        title: 'Celular'
    }, {
        text: phone,
        title: 'Teléfono'
    }, {
        text: health_insurance,
        title: 'Obra social'
    }, {
        text: health_insurance_number,
        title: 'Afiliado'
    }, {
        size: '6',
        text: email,
        title: 'Mail'
    }, {
        size: '6',
        text: address,
        title: 'Dirección'
    }, {
        size: '12',
        text: notes,
        title: 'Notas'
    }]

    return (
        <Modal active={ active } color="primary" title={ `${ surname }, ${ name } (${ gender })` } toggle={ toggle }>
            <Row>
                {
                    parsedData.map(({ size, text, title }, index) =>
                        <Col key={ index } md={ size ?? '3' } sm={ size ?? '6' } xs="12">
                            <FormInput title={ title }>
                                { !!text ? text : '-' }
                            </FormInput>
                        </Col>
                    )
                }
            </Row>
        </Modal>
    );
};

PatientModal.propTypes = {
    active: bool,
    data: object,
    toggle: func
};

export default PatientModal;
