import { Col as Col_ } from 'reactstrap';
import styled from 'styled-components';

export const Col = styled(Col_)`
    border-bottom: 1px dashed grey;
    margin-bottom: 10px;
    padding-bottom: 5px;
    :last-child {
        border-bottom: none;
    }
`;
