export const userConfig = {
    birthdayMessage: '¡Hola <NOMBRE>! Te deseo un muy feliz cumpleaños! Tu nutricionista',
    countryCode: 'AR',
    defaultDuration: 30,
    dialCode: '+54',
    firstVisitMessage: '¡Hola <NOMBRE>! Te recuerdo el turno de tu primera consulta nutricional para el día <DIA> a las <HORA>. Por favor confirmar asistencia. Saludos!',
    lastWarningDays: 30,
    lastWarningMessage: '¡Hola <NOMBRE>! Tu última visita fue el día <DIA>, ¿quisieras coordinar un nuevo turno?',
    hourFrom: '08:00',
    hourTo: '20:00',
    rememberMessage: '¡Hola <NOMBRE>! Te recuerdo el turno de tu consulta nutricional para el día <DIA> a las <HORA>. Por favor confirmar asistencia. Saludos!'
}

export const userObjectives = {
    newPatients: '',
    income: '',
    patients: '',
    plusIncome: '',
    plusIncomePercentage: '',
    plusPatients: '',
    plusPatientsPercentage: '',
    plusVisits: '',
    plusVisitsPercentage: '',
    visits: ''
}