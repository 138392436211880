export default {
    items: [{
            name: 'Inicio',
            icon: 'fa fa-home',
            url: '/principal',
        }, {
            name: 'Calendario',
            icon: 'fa fa-calendar',
            url: '/calendario',
        }, {
            name: 'Pacientes',
            icon: 'fa fa-user-o',
            url: '/pacientes',
        }, {
            name: 'Cuestionarios',
            icon: 'fa fa-list',
            url: '/cuestionarios',
        }, {
            name: 'Plantillas',
            icon: 'fa fa-whatsapp',
            url: '/plantillas',
        }, {
            name: 'Historial',
            icon: 'fa fa-rotate-left',
            url: '/historial/visitas'
        }, {
            name: 'Estadísticas',
            icon: 'fa fa-line-chart',
            url: '/estadisticas',
        }, {
            name: 'Acerca de',
            icon: 'fa fa-info',
            url: '/info',
        }
    ]
};
