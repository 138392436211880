import axios from 'axios';
import config from 'config';
import cookie from 'react-cookies';

const instance = axios.create({
    baseURL: config.API,
    headers: {
      'x-access-token': cookie.load('nutricion-auth')
    }
});

const noAuthInstance = axios.create({
    baseURL: config.API
});

instance.interceptors.request.use(config => {
    config.headers['x-access-token'] = cookie.load('nutricion-auth');
    return config;
}, function (error) {
    return Promise.reject(error);
});

export const axiosDelete = (url, data = {}) => instance.delete(url, { data });

export const axiosGet = (url, params = {}, responseType) => instance.get(url, { params, responseType });

export const axiosPut = (url, data = {}, options = {}) => instance.put(url, data, options);

export const axiosPost = (url, data = {}) => instance.post(url, data);

export const unauthAxiosGet = (url, params = {}) => noAuthInstance.get(url, { params });

export const unauthAxiosPost = (url, data = {}) => noAuthInstance.post(url, data);

export const unauthAxiosPut = (url, data = {}, options = {}) => noAuthInstance.put(url, data, options);
