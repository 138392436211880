import { AppSidebarToggler } from '@coreui/react';
import { Icon } from 'assets/img';
import React from 'react';
import UserMenu from '../UserMenu';
import { withRouter } from 'react-router-dom';
import {
    CenteredHeader,
    Container,
    LeftHeader,
    Img,
    RightHeader,
    Title
} from './styled';

const DefaultHeader = ({
    history: {
        push
    }
}) => (
    <Container>
        <LeftHeader>
            <AppSidebarToggler className="d-lg-none" display="md" mobile />
            <AppSidebarToggler className="d-md-down-none" display="lg" />
        </LeftHeader>
        <CenteredHeader>
            <Img src={ Icon } alt='' onClick={ () => push('/inicio') }/>
            <Title>
                Bamboo Nutrición
            </Title>
        </CenteredHeader>
        <RightHeader>
            <UserMenu />
        </RightHeader>
    </Container>
);

DefaultHeader.propTypes = {

};

export default withRouter(DefaultHeader);
