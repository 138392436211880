import React from 'react';
import { string } from 'prop-types';
import { Container, Icon, Subtitle, Title, Value, ValueContainer } from './styled';

function StatisticsItem({ color, icon, subtitle, title, value }) {
    return (
        <Container color={ color }>
            <Title>
                { title }
            </Title>
            <Subtitle>
                { subtitle }
            </Subtitle>
            <ValueContainer>
                <Icon>
                    <i className={ icon } />
                </Icon>
                <Value  color={ color }>
                    { value }
                </Value>
            </ValueContainer>
        </Container>
    )
}

StatisticsItem.propTypes = {
    color: string,
    icon: string,
    title: string,
    value: string
}

export default StatisticsItem

