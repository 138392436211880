import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';

export default class Terms extends Component {

    render() {
        return (
            <div>
                <Row>
                    <Col xs='12' md={{ size: 8, offset: 2 }}>
                        <Card className='card-xs'>
                            <CardHeader style={{ textAlign: 'center' }}>
                                <Label className='terms-header'>
                                    Términos y condiciones
                                </Label>
                            </CardHeader>
                            <CardBody>
                                <div style={{ overflow: 'auto', height: '80%', padding: '0px 20px' }}>
                                    <Label className='terms-title'>
                                        Criterios de aceptación
                                    </Label><br />
                                    <div className='terms-text'>
                                        <p>
                                            La creación de una cuenta dentro de la Plataforma BAMBOO (en adelante BAMBOO),
                                            implica la aceptación completa de los Términos y Condiciones vigentes en este
                                            documento. Cada persona que cree una cuenta en BAMBOO (en adelante EL USUARIO)
                                            voluntariamente acepta todas las disposiciones establecidas en el presente documento.
                                        </p>
                                        <p>
                                            Los presentes Términos y Condiciones podrán ser modificados sin previo aviso. BAMBOO
                                            notificará a los usuarios cuando ello ocurra. Las notificaciones se realizarán mediante el
                                            sistema de notificaciones de BAMBOO, así como por correo electrónico a todos aquellos
                                            USUARIOS que hayan configurado su perfil para recibir novedades del sistema.
                                        </p>
                                    </div>
                                    <Label className='terms-title'>
                                        Servicios - Acceso al sistema
                                    </Label><br />
                                    <div className='terms-text'>
                                        <p>
                                            Los servicios a prestar por BAMBOO comprenden:
                                        </p>
                                        <ul>
                                            <li>
                                                El derecho de uso y acceso no exclusivo al sitio www.bamboo-nutricion.com
                                            </li>
                                            <li>
                                                El compromiso de BAMBOO a prestar los servicios necesarios para posibilitar
                                                el acceso a la Plataforma de Servicios en condiciones de seguridad, con la
                                                debida diligencia y utilizando personal calificado. Asimismo, será responsable
                                                del almacenamiento en condiciones de seguridad de los datos que surjan en la
                                                utilización de dicha Plataforma, todo según lo dispuesto en el presente
                                                documento.
                                            </li>
                                        </ul>
                                        <p>
                                            EL USUARIO podrá acceder a BAMBOO mediante la utilización de su correo
                                            electrónico y contraseña generada al momento de la creación de la cuenta, o
                                            posteriormente modificada a través de las opciones del sistema.
                                        </p>
                                        <p>
                                            BAMBOO se reserva el derecho de bloquear el acceso a la cuenta, debido al
                                            incumplimiento de los Términos y Condiciones establecidos en el presente
                                            documento.
                                        </p>
                                        <p>
                                            Dentro de las funcionalidades del sistema se brindará la opción para que EL USUARIO
                                            pueda eliminar su cuenta, de forma que no quede accesible a través de ningún mail
                                            ni contraseña. Adicionalmente, EL USUARIO tendrá la posibilidad de crear una nueva
                                            cuenta con el mail de la cuenta eliminada.
                                        </p>
                                        <p>
                                            Para dejar de utilizar BAMBOO no es necesario que el USUARIO elimine su cuenta. La
                                            misma podrá quedar disponible, con toda la información asociada, por si EL USUARIO
                                            decide volver a utilizar el Servicio.
                                        </p>
                                    </div>
                                    <Label className='terms-title'>
                                        Costos - Modalidad de contratación - Acceso a la información
                                    </Label><br />
                                    <div className='terms-text'>
                                        <p>
                                            Para acceder al Servicio, El USUARIO abonará a BAMBOO un cargo fijo mensual de
                                            acuerdo a lo establecido en la sección “Precios” del sitio web www.bamboo-
                                            nutrición.com. En cualquier momento BAMBOO podrá modificar los precios
                                            establecidos, previa comunicación a los USUARIOS con 30 días de antelación.
                                        </p>
                                        <p>
                                            La modalidad de contratación del Servicio será mediante el sistema “Prepago”, es
                                            decir que EL USUARIO deberá abonar el cargo fijo mensual por adelantado. A partir
                                            de la fecha de pago, el sistema acreditará el período de un (1) mes calendario en su
                                            cuenta, habilitando el uso del Servicio. Si EL USUARIO realiza el pago previo a la
                                            fecha de vencimiento del período vigente, el nuevo período se acreditará a partir de
                                            la fecha de vencimiento del vigente.
                                        </p>
                                        <p>
                                            En caso de no registrarse el pago de un nuevo período a la fecha de vencimiento del
                                            período vigente, la cuenta del USUARIO entrará en modo de “solo lectura”. Esto
                                            quiere decir que EL USUARIO siempre podrá acceder a los datos que haya cargado en
                                            el sistema, pero la capacidad de edición o ingreso de nuevos datos se encontrará
                                            limitada.
                                        </p>
                                        <p>
                                            Cualquier diferencia con los pagos entre EL USUARIO y BAMBOO será resuelta
                                            mediante común acuerdo entre ambos. En caso de no alcanzar un acuerdo por la no
                                            aceptación de alguna de las partes, BAMBOO tendrá la decisión final sobre la
                                            prestación del servicio.
                                        </p>
                                    </div>
                                    <Label className='terms-title'>
                                        Propiedad de los datos - Privacidad - Seguridad - Contenido - Responsabilidad
                                    </Label><br />
                                    <div className='terms-text'>
                                        <p>
                                            BAMBOO utiliza todos los medios técnicos a su alcance para la protección de los
                                            datos. Los enunciados de privacidad se aplican a la información ingresada por EL
                                            USUARIO al sistema mediante sus diferentes interfaces.
                                        </p>
                                        <p>
                                            Los datos cargados por EL USUARIO se encuentran protegidos mediante una
                                            contraseña especificada por éste. Dicha contraseña se encuentra cifrada en la base
                                            de datos de BAMBOO y no es conocida por nadie más que EL USUARIO, por lo cual es
                                            su responsabilidad no revelarla a terceros. Bajo ninguna circunstancia BAMBOO
                                            solicitará la contraseña en comunicaciones con EL USUARIO.
                                        </p>
                                        <p>
                                            La propiedad de los datos es exclusiva del USUARIO. Será obligación de BAMBOO
                                            generar una copia de la base de datos que procese y/o tomar las medidas necesarias
                                            a efectos de su conveniente resguardo. La responsabilidad del BAMBOO se limitará a
                                            la seguridad de la información, como así también al mantenimiento actualizado de
                                            copias de seguridad (backup), para lo cual deberá tomar los recaudos oportunos y
                                            necesarios equivalentes a los que utiliza con su propia información y datos.
                                        </p>
                                        <p>
                                            BAMBOO no será responsable por la naturaleza, uso, destino de los datos
                                            almacenados o administrados, como así tampoco será responsable por los daños o
                                            inconsistencias en la información que surgieren por el mal uso del Sistema.
                                        </p>
                                        <p>
                                            Asimismo, BAMBOO tampoco responderá por la pérdida o daño total o parcial de los
                                            datos objeto de los servicios prestados, producido por fallas en equipos, redes,
                                            instalaciones eléctricas y otras instalaciones en general, red de energía eléctrica,
                                            proveedor de Internet u otras aplicaciones, siempre que excedan las medidas de
                                            seguridad provistas por BAMBOO comprometidas en estos Términos y Condiciones.
                                        </p>
                                        <p>
                                            El envío de datos mediante BAMBOO se encuentra cifrado bajo el protocolo HTTPS
                                            para mayor seguridad, protegiendo la información que viaja de los ataques más
                                            comunes.
                                        </p>
                                        <p>
                                            EL USUARIO actúa como una entidad independiente y en consecuencia es
                                            responsable de los riesgos de su actividad. De esta manera, es responsable del
                                            contenido, precisión, calidad, integridad, fiabilidad, adecuación, veracidad,
                                            actualización y legalidad de los datos, información y archivos que se organicen,
                                            gestionen, transmitan, difundan o recojan como resultado de la prestación de
                                            servicios. BAMBOO no está obligado a comprobar el contenido de los datos
                                            suministrados por EL USUARIO.
                                        </p>
                                        <p>
                                            EL USUARIO asegura que el contenido y utilización de los datos que se gestionan no
                                            faltan a las buenas costumbres, no viola los derechos de terceros ni viola las leyes
                                            vigentes, incluyendo pero no limitándose a: infracción de derecho de autor, derecho
                                            de intérprete, derechos de organismos de radiodifusión, derechos de patentes,
                                            derecho de marcas, secretos comerciales, o cualquier otra propiedad intelectual, ni
                                            incurre en publicidad falsa, engañosa o denigratoria, ni en competencia desleal,
                                            difamación, invasión de la privacidad, usurpación de los derechos de la personalidad,
                                            ni atenta contra cualquier otro derecho o garantía protegida por las leyes argentinas.
                                        </p>
                                        <p>
                                            En caso de tratarse de “Datos Personales” de los Pacientes, según lo dispuesto en la
                                            ley 25.326 (Ley de Protección de Datos Personales), sus modificatorias y
                                            reglamentación, se entiende que EL USUARIO será considerado titular y responsable
                                            del registro y asumirá las siguientes obligaciones enumeradas de manera
                                            ejemplificativa:
                                        </p>
                                        <ul>   
                                            <li>
                                                Veracidad y pertinencia de los datos.
                                            </li>
                                            <li>
                                                Recolección de datos por medios leales, no fraudulentos o en forma contraria
                                                a las disposiciones de la ley.
                                            </li>
                                            <li>
                                                Utilización de los datos con la finalidad que motivaron su recolección.
                                            </li>
                                            <li>
                                                Que el Paciente haya prestado en consentimiento para el tratamiento de sus
                                                datos personales y para su cesión.
                                            </li>
                                            <li>
                                                De acuerdo a lo dispuesto por el art. 25 de la ley 25.326 no se considerará
                                                cesión o traspaso de datos personales, el tratamiento que de estos realice
                                                BAMBOO o su personal, siempre que fuere indispensable para la prestación de
                                                las obligaciones previstas en estos Términos y Condiciones.
                                            </li>
                                            <li>
                                                Conforme a lo dispuesto por el art. 25 del Decreto Reglamentario 1558/01 de
                                                la ley citada, BAMBOO será considerado “encargado del tratamiento de los
                                                datos personales” y se compromete a utilizar los datos a los que acceda a los
                                                fines exclusivos de este contrato, y únicamente tratara los datos conforme a
                                                las instrucciones del responsable y titular de la base de datos. En ningún caso
                                                los aplicará ni utilizará con un fin distinto al objeto de este convenio, ni los
                                                comunicará a terceras personas.
                                            </li>
                                            <li>
                                                EL USUARIO como responsable y titular de sus datos, se obliga a adoptar las
                                                mejores medidas de índole técnica y organizativas necesarias que garanticen
                                                la seguridad y confidencialidad de los datos de carácter personal y eviten su
                                                alteración, pérdida, tratamiento, acceso no autorizado y que permitan
                                                detectar desviaciones, de información, ya sea que los riesgos provengan de la
                                                acción humana o del medio técnico utilizado.
                                            </li>
                                        </ul>
                                        <p>
                                            BAMBOO se compromete a no distribuir, vender o facilitar a terceras personas la
                                            información disponible sobre EL USUARIO, salvo previa autorización expresa por
                                            parte de éste último.
                                        </p>
                                        <p>
                                            BAMBOO podrá hacer uso de información estadística resultante de la interacción del
                                            USUARIO con la plataforma, como secciones visitadas, botones presionados y otras
                                            interacciones sobre interfaces. La información cargada por EL USUARIO sobre los
                                            Pacientes es confidencial, y BAMBOO no la utilizará ni accederá para obtener
                                            conclusiones de ningún tipo.
                                        </p>
                                        <p>
                                            EL USUARIO deberá adoptar las medidas de seguridad que se dicten conforme a la
                                            ley 25.326 y sus reglamentaciones, entre las que se encuentran: no enviar o
                                            almacenar material que contenga software con virus, gusanos, caballos de Troya u
                                            otro código, archivo, agentes o programas de computación perjudicial, etc.
                                        </p>
                                        <p>
                                            Si existe riesgo de que los datos almacenados falten a lo dispuesto por la presente
                                            cláusula, BAMBOO está autorizado a eliminarlos de inmediato y queda exento de sus
                                            obligaciones de servicio.
                                        </p>
                                    </div>
                                    <Label className='terms-title'>
                                        Obligaciones - Responsabilidades - Garantías
                                    </Label><br />
                                    <div className='terms-text'>
                                        <p>
                                            Al ser BAMBOO una herramienta para EL USUARIO, su utilización en forma ética y
                                            profesional es de completa responsabilidad del USUARIO, por lo cual serán
                                            obligaciones del mismo:
                                        </p>
                                        <ul>
                                            <li>
                                                No revelar información sensible de los pacientes sin su consentimiento.
                                            </li>
                                            <li>
                                                No suplantar la identidad de otra persona, tanto en el acceso como en la
                                                creación de la cuenta.
                                            </li>
                                            <li>
                                                No publicar información falsa o incorrecta en cualquier medio sobre BAMBOO.
                                            </li>
                                            <li>
                                                No intentar dañar la plataforma de manera voluntaria.
                                            </li>
                                        </ul>
                                        <p>
                                            Cualquier archivo cargado dentro del sistema, así como fotos y documentos, serán de
                                            exclusiva responsabilidad del USUARIO. Dichos archivos no serán revisados por
                                            BAMBOO, por lo que será obligación del USUARIO validar su contenido y legitimidad.
                                        </p>
                                        <p>
                                            Debido a la posibilidad de problemas propios de la red Internet y otras contingencias
                                            imprevisibles y de fuerza mayor, BAMBOO no puede garantizar que la disponibilidad
                                            del acceso al servicio, será continua e ininterrumpida durante el período de vigencia
                                            del presente y por lo tanto no puede aceptar responsabilidades derivadas de
                                            interrupción del negocio del USUARIO o cualesquiera otros perjuicios producidos por
                                            su funcionamiento. Serán considerados como casos de fuerza mayor o caso fortuito,
                                            sin carácter limitativo, el retraso, fallo, suspensión o interrupción del acceso al
                                            Servicio como consecuencia de las restricciones de energía, bloqueo de las
                                            telecomunicaciones o de la red Internet, acciones u omisiones de terceras personas,
                                            operadores de telecomunicaciones o compañías de servicios, suministro o transportes
                                            o cualesquiera otras causas o circunstancias independientes de la voluntad de
                                            BAMBOO.
                                        </p>
                                        <p>
                                            BAMBOO se reserva el derecho de interrumpir la conexión de EL USUARIO al servicio,
                                            si constituye un peligro para el mantenimiento de la seguridad del mismo, ya sea
                                            como consecuencia a un pirateo en los servidores, o la detección de un fallo en la
                                            seguridad del sistema, o a una necesidad de puesta al día del servidor (intervención
                                            técnica al servidor para mejorar el funcionamiento). BAMBOO informará por
                                            adelantado, y en la medida de lo posible, a EL USUARIO en un plazo razonable,
                                            informando de la naturaleza y de la duración de la intervención, a fin de que EL
                                            USUARIO pueda tomar las medidas necesarias.
                                        </p>
                                        <p>
                                            BAMBOO no asegura que el Servicio funcionará ininterrumpidamente ni que estará
                                            libre de errores. BAMBOO no asume ninguna otra responsabilidad ni otorga ninguna
                                            otra garantía expresa o implícita respecto de las consecuencias dañosas que
                                            eventualmente puedan derivar a EL USUARIO o a terceros de la utilización o no
                                            funcionamiento del Servicio. EL USUARIO será enteramente responsable de definir
                                            los resultados a obtener mediante la utilización del Servicio, así como de determinar
                                            si su utilización resulta adecuada a sus necesidades. Ambas partes eximen por tanto
                                            a la otra parte, de cualquier responsabilidad que derive de la prestación
                                            comprometida, como de los resultados y/o las decisiones comerciales y/o
                                            empresarias que se tomen o dejen de tomar, en virtud de las mismas. Ninguna de las
                                            PARTES será responsable de los retrasos, incumplimientos y/o cualquier otro hecho o
                                            acto no imputable a ella.
                                        </p>
                                        <p>
                                            EL USUARIO tendrá el control exclusivo de la prestación que le fue concedida, por lo
                                            que en todo tiempo será responsable de la utilización que se haga de la misma. En
                                            consecuencia, EL USUARIO libera expresamente a BAMBOO de toda responsabilidad
                                            de cualquier tipo derivada del uso al que se refieren los presentes Términos y
                                            Condiciones. EL USUARIO deberá abstenerse de utilizar el Servicio con fines o efectos
                                            ilícitos o que lesionen los derechos e intereses de terceros o de BAMBOO, bajo
                                            apercibimiento de suspender BAMBOO el Servicio y reclamar los daños y perjuicios
                                            que su proceder hubiesen ocasionado.
                                        </p>
                                        <p>
                                            EL USUARIO asume que la prestación del Servicio, puede estar sujeta a limitaciones,
                                            retrasos y otros inconvenientes provenientes del uso normal de Internet. En
                                            consecuencia EL USUARIO reconoce que BAMBOO carece de toda responsabilidad
                                            que pueda estar originada en estas causas
                                        </p>
                                        <p>
                                            Dentro de la plataforma de BAMBOO se podrán encontrar eventualmente links a
                                            sitios externos. El acceso a los mismos será responsabilidad completa del usuario, así
                                            como la aceptación de los términos y condiciones de dichos sitios.
                                        </p>
                                    </div>
                                    <Label className='terms-title'>
                                        Uso de cookies
                                    </Label><br />
                                    <div className='terms-text'>
                                        <p>
                                            Bamboo utilizará cookies para almacenar la información de la sesión del usuario. Sólo se
                                            mantendrá en la misma, información de autenticación a la plataforma, que es necesaria para el
                                            mantenimiento de la sesión conectada.
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                            <CardFooter style={{ textAlign: 'center' }}>
                                <Button color='success' onClick={ () => this.props.history.goBack() || window.close() }>Volver</Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
