import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { Component, Fragment } from 'react';
import { bool, func, string } from 'prop-types';


class ConfirmButton extends Component {

    static propTypes = {
        message: string,
        onAccept: func,
        onAcceptText: string,
        onCancelText: string,
        shown: bool,
        size: string,
        text: string,
        toggleFunction: func
    }

    render() {
        let { color, message, onAccept, onAcceptText, onCancelText, shown, size, style = {}, text, toggleFunction } = this.props;
        return (
            <Fragment>
                <Button className='d-print-none' size={size || 'md'} color={color || 'danger'} style={ style } onClick={toggleFunction}>{text}</Button>
                <Modal isOpen={shown} toggle={toggleFunction} className={`modal-sm modal-${color || 'danger'}`}>
                    <ModalHeader />
                    <ModalBody>
                        {message}
                    </ModalBody>
                    <ModalFooter>
                        <Button color={ color || 'danger'} onClick={onAccept}>{onAcceptText || 'Salir'}</Button>{' '}
                        <Button color='secondary' onClick={toggleFunction}>{onCancelText || 'Cancelar'}</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>

        );
    }
}

export default ConfirmButton;