export const prefixes = [{
    dialCode: '+54',
    code: 'AR',
    name: 'Argentina'
}, {
    dialCode: '+591',
    code: 'BO',
    name: 'Bolivia',
}, {
    dialCode: '+55',
    code: 'BR',
    name: 'Brasil'
}, {
    dialCode: '+1',
    code: 'CA',
    name: 'Canadá'
}, {
    dialCode: '+56',
    code: 'CL',
    name: 'Chile'
}, {
    dialCode: '+57',
    code: 'CO',
    name: 'Colombia'
}, {
    dialCode: '+506',
    code: 'CR',
    name: 'Costa Rica',
}, {
    dialCode: '+53',
    code: 'CU',
    name: 'Cuba',
}, {
    dialCode: '+593',
    code: 'EC',
    name: 'Ecuador'
}, {
    dialCode: '+1',
    code: 'US',
    name: 'Estados Unidos',
}, {
    dialCode: '+34',
    code: 'ES',
    name: 'España'
}, {
    dialCode: '+502',
    code: 'GT',
    name: 'Guatemala'
}, {
    dialCode: '+504',
    code: 'HN',
    name: 'Honduras'
}, {
    dialCode: '+52',
    code: 'MX',
    name: 'México'
}, {
    dialCode: '+505',
    code: 'NI',
    name: 'Nicaragua'
}, {
    dialCode: '+507',
    code: 'PA',
    name: 'Panamá'
}, {
    dialCode: '+595',
    code: 'PY',
    name: 'Paraguay'
}, {
    dialCode: '+51',
    code: 'PE',
    name: 'Perú',
}, {
    dialCode: '+1',
    code: 'PR',
    name: 'Puerto Rico',
}, {
    dialCode: '+598',
    code: 'UY',
    name: 'Uruguay'
}, {
    dialCode: '+58',
    code: 'VE',
    name: 'Venezuela',
}]
