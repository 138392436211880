import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { bool, func, string } from 'prop-types';

class PrimaryDangerFooter extends Component {

    static propTypes = {
        dangerColor: string,
        dangerMessage: string,
        dangerText: string,
        disabled: bool,
        onDanger: func,
        onPrimary: func,
        onPrimaryAcceptText: string,
        onPrimaryCancelText: string,
        onDangerAcceptText: string,
        onDangerCancelText: string,
        primaryColor: string,
        primaryMessage: string,
        primaryText: string,
        primaryValidation: func
    }

    constructor(props) {
        super(props);
        this.state = {
            modalDanger : false,
            modalPrimary : false,
        }
    }

    onDanger = () => {
        const { onDanger } = this.props;
        onDanger();
    }

    onPrimaryClick = () => {
        const { onPrimary } = this.props;
        onPrimary();
    }

    toggleDanger = () => {
        const { modalDanger } = this.state;
        this.setState({
            modalDanger : !modalDanger
        });
    }

    togglePrimary = () => {
        const { modalPrimary } = this.state;
        const { primaryValidation = () => true } = this.props;
        if (primaryValidation()) {
            this.setState({
                modalPrimary : !modalPrimary
            });
        }
    }

    render() {
        const {
            disabled,
            disabledPrimary,
            hideDanger,
            hidePrimary,
            primaryColor,
            primaryText,
            primaryMessage,
            onPrimaryAcceptText,
            dangerColor,
            dangerText,
            onPrimaryCancelText,
            dangerMessage,
            onDangerAcceptText,
            onDangerCancelText
        } = this.props;
        const { modalDanger, modalPrimary } = this.state;
        return (
            <div className="d-flex justify-content-center d-print-none" style={{ marginBottom: 30 }}>
                {
                    !hidePrimary &&
                    <Fragment>
                        <Button size="md" color={primaryColor || 'primary'} onClick={this.togglePrimary} disabled={ disabled || disabledPrimary || false} style={{ marginRight: 15 }}>
                            { primaryText || 'Guardar' }
                        </Button>
                        <Modal isOpen={ modalPrimary } toggle={ () => this.togglePrimary() } className={'modal-sm modal-' + (primaryColor || 'primary') }>
                            <ModalHeader />
                            <ModalBody>
                                {primaryMessage}
                            </ModalBody>
                            <ModalFooter>
                                <Button color={primaryColor || 'primary'} onClick={ () => this.onPrimaryClick() } disabled={disabled || false} >
                                    { onPrimaryAcceptText || 'Guardar' }
                                </Button>{' '}
                                <Button color="secondary" onClick={this.togglePrimary} disabled={disabled || false}>
                                    { onPrimaryCancelText || 'Cancelar' }
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Fragment>
                }
                {
                    !hideDanger &&
                    <Fragment>
                        <Button size="md" color={dangerColor || 'danger'} onClick={this.toggleDanger} disabled={ disabled || false} > 
                            { dangerText || 'Cancelar' }
                        </Button>
                        <Modal isOpen={ modalDanger } toggle={ () => this.toggleDanger() } className={'modal-sm modal-' + (dangerColor || 'danger')}>
                            <ModalHeader />
                            <ModalBody>
                                {dangerMessage}
                            </ModalBody>
                            <ModalFooter>
                                <Button color={dangerColor || 'danger'} onClick={this.onDanger}>{onDangerAcceptText || 'Salir'}</Button>{' '}
                                <Button color="secondary" onClick={this.toggleDanger}>{onDangerCancelText || 'Cancelar'}</Button>
                            </ModalFooter>
                        </Modal>
                    </Fragment>
                }
            </div >
        );
    }
}

export default PrimaryDangerFooter;