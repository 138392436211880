import styled from 'styled-components';
import { colors } from 'styled';
const { red } = colors;

export const ChildrenContainer = styled.div`
    width: 100%;
`;

export const Container = styled.div`
    margin-bottom: 5px;
    width: 100%;
`;

export const Required = styled.div`
    color: ${ red };
    margin-left: 5px;
`;

export const Title = styled.div`
    display: flex;
    font-size: 11px;
    margin-bottom: 5px;
`;
