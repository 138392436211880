import { AppAsideToggler } from '@coreui/react';
import { withRouter } from 'react-router-dom';
import { AppSideContainer, Container, DropdownContainer, DropdownToggle } from './styled';
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import React, { Component } from 'react';
import { getLastPayment, getUser, getLogOut } from 'core/User/services';

class UserMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownProfile: false,
            modalLogout: false,
            lastPayment: {
                days: ''
            },
            user: {
                name: '',
                surname: ''
            }
        }
    }

    componentDidMount = async () => {
        try {
            const [
                lastPayment,
                user
            ] = [
                await getLastPayment(),
                await getUser()
            ];
            this.setState({
                days: lastPayment.days,
                user
            })
        } catch (err) {

        }
    }

    logout = async () => {
        try {
            await getLogOut();
        } catch (err) {

        } finally {
            const { history: { push } } = this.props;
            push({
                pathname: '/login'
            });
        }
    }

    toggle = name => {
        const { [name]: value } = this.state;
        this.setState({
            [name]: !value
        })
    }

    getPaymentStatus = (days) => {
        if (days >= 15) {
            return {
                color: 'success',
                text: `Te quedan ${days} días`
            }
        } else if (days >= 7) {
            return {
                color: 'warning',
                text: `Te quedan ${days} días`
            }
        } else if (days > 1) {
            return {
                color: 'danger',
                icon: 'fa fa-exclamation',
                margin: 8,
                text: `Te quedan ${days} días`,
            }
        } else if (days === 1) {
            return {
                color: 'danger',
                icon: 'fa fa-question-circle',
                margin: 4,
                text: `Mañana es tu último día`
            }
        } else if (days === 0) {
            return {
                color: 'danger',
                icon: 'fa fa-question-circle',
                text: `Hoy es tu último día`
            }
        } else {
            return {
                color: 'danger',
                icon: 'fa fa-question-circle',
                text: `No podés cargar datos`
            }
        }
    }

    getItemStyle = path => {
        const { location: { hash } } = window;
        return path === hash ? { backgroundColor: '#EDF8F1' } : {};
    }

    render() {
        const { days, dropdownProfile, modalLogout, user: { name, surname } } = this.state;
        const { history: { push } } = this.props;
        let { color, text } = this.getPaymentStatus(days);
        return (
            <Container>
                <DropdownContainer>
                    <Dropdown isOpen={ dropdownProfile } toggle={ () => this.toggle('dropdownProfile') }>
                        <DropdownToggle className="header-menu-icon" color="link">
                            <Label className="header-dropdown-label">
                                Perfil
                            </Label>{ ' ' }
                            <Label className="header-dropdown-label-icon">
                                <i className={ `fa fa-${ !dropdownProfile ? 'user-circle-o' : 'close' }` } />
                            </Label>
                        </DropdownToggle>
                        <DropdownMenu className={ `header-dropdown ${ dropdownProfile ? 'max-content' : '' }` } color="success" size="sm">
                            <DropdownItem header>{ `${ surname }, ${ name }` }</DropdownItem>
                            <DropdownItem className="header-dropdown-item" onClick={() => push('/configuracion') } style={ this.getItemStyle('#/configuracion') }>
                                <div className="header-dropdown-item-container">
                                    <i className="fa fa-cog" /> Configuración
                                </div>
                            </DropdownItem>
                            <DropdownItem className="header-dropdown-item" onClick={() => push('/contacto') } style={ this.getItemStyle('#/contacto') }>
                                <div className="header-dropdown-item-container">
                                    <i className="fa fa-envelope-o" /> Contacto
                                </div>
                            </DropdownItem>
                            <DropdownItem className="header-dropdown-item" onClick={() => push('/pagos') } style={ this.getItemStyle('#/pagos') }>
                                <div className="header-dropdown-item-container">
                                    <i className="fa fa-dollar" /> Pagos
                                </div>
                            </DropdownItem>
                            <DropdownItem className={ `header-dropdown-disabled ${ color }` } disabled id="dropdown-item-payment">
                                { `- ${ text } -` }
                            </DropdownItem>
                            <DropdownItem className="header-dropdown-item" onClick={ () => this.toggle('modalLogout') }>
                                <div className="header-dropdown-item-container">
                                    <i className="fa fa-close" /> Cerrar sesión
                                </div>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </DropdownContainer>
                <AppSideContainer>
                    <AppAsideToggler className="d-md-down-none">
                        <span className="navbar-toggler-icon no-background">
                            <i className="fa fa-question-circle" />
                        </span>
                    </AppAsideToggler>
                    <AppAsideToggler className="d-lg-none" mobile>
                        <span className="navbar-toggler-icon no-background">
                            <i className="fa fa-question-circle" />
                        </span>
                    </AppAsideToggler>
                </AppSideContainer>
                <Modal isOpen={modalLogout} toggle={ () => this.toggle('modalLogout') } className={ 'modal-sm modal-secondary' }>
                    <ModalHeader />
                    <ModalBody>
                        ¿Deseas salir del sistema?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='dark' onClick={this.logout}>Salir</Button>{' '}
                        <Button color='secondary' onClick={ () => this.toggle('modalLogout') }>Volver</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        )
    }
}

export default withRouter(UserMenu);
