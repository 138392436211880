import { getPatientNames } from 'core/Patient/services';
import Select from 'react-select';
import 'react-select/dist/react-select.min.css';
import React, { Component, Fragment } from 'react';
import { func, number, string } from 'prop-types';
const titleize = require('titleize');

class PatientPicker extends Component {
    static propTypes = {
        name: string,
        onChange: func,
        placeholder: string,
        selected: number
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            patients: []
        }
    }

    async componentDidMount(){
        try{
            const patients = await getPatientNames();
            patients.forEach(patient => {
                const { id, name, surname } = patient;
                patient.label = `${titleize(`${surname}, ${name}`)}`;
                patient.value = id;
            });
            this.setState({
                loading: false,
                patients
            })
        } catch (err) {
            
        }
    }

    render() {
        const { name, onChange, placeholder, selected } = this.props;
        const { loading, patients } = this.state;
        return (
            <Fragment>
                <Select
                    autoFocus={ true }
                    isClearable={ false }
                    isLoading={ loading }
                    loadingMessage="Cargando"
                    name={ name }
                    noResultsText="No se encontraron pacientes"
                    options={ patients }
                    placeholder={ placeholder || 'Seleccioná un paciente'}
                    value={ selected }
                    onChange={ onChange }
                    getOptionValue={ option => option.id }
                />
            </Fragment>

        );
    }
}

export default PatientPicker;