import React from 'react';
import { getContextualInfo } from './fixtures';
import { withRouter } from 'react-router-dom';
import { Container, ContextualInfo, Separator, Title } from './styled';

const AsideBar = ({
    location: {
        pathname
    }
}) => {

    return (
        <Container>
            <Title>Panel de ayuda</Title>
            <Separator />
            <ContextualInfo>
                { getContextualInfo(pathname) }
            </ContextualInfo>
            <Separator marginTop="10px"/>
        </Container>
    )
};

AsideBar.propTypes = {

};

export default withRouter(AsideBar);

