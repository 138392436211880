import { Button } from 'reactstrap';
import React from 'react';
import { string } from 'prop-types';

const PrintButton = ({
    title
}) => {

    const onPrint = () => {
        if (!!title) { document.title=title };
        window.print();
        document.title="Bamboo Nutrición";
    };

    return (
        <Button className='d-print-none' color='success' size="sm" onClick={ onPrint }>
            Imprimir <i className='fa fa-print' />
        </Button>
    );
};

PrintButton.propTypes={
    title: string
}

export default PrintButton;
