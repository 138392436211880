import React, { Component, Fragment } from 'react';
import config from 'config';
import { postRegisterUser } from 'core/User/services';
import { Button, Card, CardBody,  Col, Container, FormFeedback, Label, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Field, Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

const validationSchema = function (values) {
    return Yup.object().shape({
        surname: Yup.string()
            .required('Ingresá tu apellido'),
        name: Yup.string()
            .required('Ingresá tu nombre'),
        email: Yup.string()
            .required('Ingresá tu email')
            .email('Ingresá un email correcto'),
        pass: Yup.string()
            .required('Ingresá una contraseña'),
        passRepeat: Yup.string()
            .oneOf([Yup.ref('pass'), null], 'Las contraseñas deben coincidir')
            .required(''),
        referred_email: Yup.string()
            .email('Ingresá un email correcto')
    })
}

const validate = (getValidationSchema) => {
    return (values) => {
        const validationSchema = getValidationSchema(values)
        try {
            validationSchema.validateSync(values, { abortEarly: false })
            return {}
        } catch (error) {
            return getErrorsFromValidationError(error)
        }
    }
}

const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0
    return validationError.inner.reduce((errors, error) => {
        return {
            ...errors,
            [error.path]: error.errors[FIRST_ERROR],
        }
    }, {})
}

class Register extends Component {
    constructor(props){
        super(props);
        this.state = {
            registered: false,
            loading: false,
            error: ''
        }
    }

    login = () => {
        this.props.history.push({
            pathname: './login'
        })
    }

    openTerms = e => {
        e.preventDefault();
        window.open(`${ config.path }terminos`, '_blank')
    }

    register = async (values) => {
        try{
            this.setState({
                error: '',
                loading: true
            });
            values.email = values.email.toLowerCase();
            delete values.passRepeat;
            await postRegisterUser(values);
            values.passRepeat = values.pass;
            this.setState({
                loading: false,
                registered: true
            })
        } catch ({response}) {
            values.passRepeat = values.pass;
            this.setState({
                loading: false,
                error: response.data.err
            })
        }

    }

    render() {
        let { error, loading, registered } = this.state;
        return (
            <div className='app flex-row align-items-center'>
                <Formik
                    validate={validate(validationSchema)}
                    onSubmit={this.register}
                    initialValues={{
                        surname: '',
                        name: '',
                        email: '',
                        pass: '',
                        passRepeat: '',
                        referred_email: ''
                    }}
                    render={({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                    }) => (
                            <Container>
                                <Row className='justify-content-center'>
                                    <Col md='6'>
                                        <Card className='p-4 card-xs'>
                                            <CardBody className='p-4'>
                                                <h1>Creá tu cuenta</h1>
                                                <p className='text-muted'>Ingresá tus datos</p>
                                                <InputGroup className='mb-3'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText><i className='cui-user' /></InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field className='form-control' name='surname' placeholder='Apellido' readOnly={ registered }/>
                                                    <FormFeedback>{ touched.surname && errors.surname }</FormFeedback>
                                                </InputGroup>
                                                <InputGroup className='mb-3'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText><i className='cui-user' /></InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field className='form-control' name='name' placeholder='Nombre' readOnly={ registered } />
                                                    <FormFeedback>{ touched.name && errors.name}</FormFeedback>
                                                </InputGroup>
                                                <InputGroup className='mb-3'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText>@</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field className='form-control' name='email' placeholder='Email' readOnly={registered} type='email' />
                                                    <FormFeedback>{ touched.email && errors.email}</FormFeedback>
                                                </InputGroup>
                                                {
                                                    !registered && 
                                                    <Fragment>
                                                        <InputGroup className='mb-3'>
                                                            <InputGroupAddon addonType='prepend'>
                                                                <InputGroupText>
                                                                    <i className='icon-lock'></i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Field className='form-control' name='pass' placeholder='Contraseña' readOnly={registered} type='password' />
                                                            <FormFeedback>{ touched.pass && errors.pass }</FormFeedback>
                                                        </InputGroup>
                                                        <InputGroup className='mb-3'>
                                                            <InputGroupAddon addonType='prepend'>
                                                                <InputGroupText>
                                                                    <i className='icon-lock'></i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Field className='form-control' name='passRepeat' placeholder='Contraseña' readOnly={registered} type='password' />
                                                            <FormFeedback>{touched.passRepeat && errors.passRepeat}</FormFeedback>
                                                        </InputGroup>
                                                        <InputGroup className='mb-3'>
                                                            <InputGroupAddon addonType='prepend'>
                                                                <InputGroupText>@</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Field className='form-control' name='referred_email' placeholder='Cuenta referida' readOnly={registered} type='email' />
                                                            <FormFeedback>{touched.referred_email && errors.referred_email}</FormFeedback>
                                                        </InputGroup>
                                                        <Label className='label-muted' style={{ marginTop: -15 }}>
                                                            Si algún colega te recomendó el sistema, podés ingresar su cuenta para brindarle beneficios
                                                        </Label>
                                                        <Label style={{ textAlign:'justify' }}>
                                                            Creando una cuenta y utilizando Bamboo aseguras haber leído y aceptado los 
                                                            <Link to='/terminos' onClick={ this.openTerms }> términos y condiciones</Link>
                                                            .
                                                        </Label>
                                                    </Fragment>
                                                }
                                                {
                                                    error.length > 0 &&
                                                    <Label style={{color: 'red' }}>{error}</Label>
                                                }
                                                {
                                                    !registered &&
                                                    <Fragment>
                                                        <Button color='success' block onClick={handleSubmit} disabled={loading}>Crear cuenta</Button>
                                                        <Button size='sm' onClick={this.login} color='link' style={{ color: '#4dbd74', marginLeft: -5 }}>Ya tengo una cuenta</Button>
                                                    </Fragment>
                                                }           
                                                {
                                                    registered && error.length === 0 &&
                                                    <Fragment>
                                                        <Label style={{ textAlign: 'justify' }}>Enviamos un correo para activar la cuenta. Si no lo encontrás, por favor revisá el correo no deseado.</Label>
                                                        <Button color='success' block onClick={this.login} disabled={loading}>Ir al inicio de sesión</Button>
                                                    </Fragment>
                                                }                                          
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                    )}/>
            </div>
        );
    }
}

export default Register;
