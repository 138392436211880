import styled from 'styled-components';
import { colors, mediaQuery } from 'styled';
const { gray } = colors;

export const Container = styled.div`
    border: solid 2px ${ ({ color }) => color };
    border-radius: 10px;
    box-shadow: 1px 1px 3px ${ gray };
    height: 170px;
    padding: 10px;
    ${ mediaQuery('to sm', `
        margin-top: 10px;
    `) }
`;

export const Icon = styled.div`
    align-items: center;
    color: ${ gray };
    display: inline-flex;
    font-size: 50px;
    justify-content: center;
    width: 50%;
`;

export const Subtitle = styled.div`
    font-size: 12px;
    font-style: italic;
    height: 20px;
`;

export const Title = styled.div`
    font-size: 14px;
    height: 20px;
`;

export const Value = styled.div`
    align-items: start;
    color: ${ ({ color }) => color };
    display: inline-flex;
    font-size: 60px;
    font-weight: 600;
    justify-content: center;
    text-shadow: 1px 1px 1px ${ gray };
    width: 50%;
`;

export const ValueContainer = styled.div`
    height: 110px;
`;
