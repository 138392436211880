import { Col as Col_, Label, Row as Row_ } from 'reactstrap';
import styled from 'styled-components';

export const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

export const mediaQuery = (query, content) => {
    const [compare, breakpoint] = query.split(' ');
    let size = 0;
    if (compare === 'from') {
        switch (breakpoint) {
            case('sm'): size = 576; break;
            case('md'): size = 768; break;
            case('lg'): size = 992; break;
            case('xl'): size = 1200; break;
            default: break;
        }
    } else if (compare === 'to') {
        switch (breakpoint) {
            case('xs'): size = 575; break;
            case('sm'): size = 767; break;
            case('md'): size = 991; break;
            case('lg'): size = 1199; break;
            default: break;
        }
    }
    return `@media (${ compare === 'from' ? 'min' : 'max' }-width: ${ size }px) { ${ content } }`;
}

export const colors = {
    blue: '#20A8D8',
    cyan: '#17A2B8',
    danger: '#F86C6B',
    dark: '#2F353A',
    darkGreen: '#307548',
    gray: '#73818F',
    green: '#4DBD74',
    greyDark: '#2F353A',
    light: '#F0F3F5',
    lightBlue: '#63C2DE',
    lime: '#BFFF00',
    indigo: '#6610F2',
    info: '#63C2DE',
    orange: '#FF7B07',
    pink: '#E83E8C',
    presuccess: '#A9CF42',
    primary: '#20A8D8',
    purple: '#6F42C1',
    secondary: '#C8CED3',
    success: '#4DBD74',
    teal: '#20C997',
    red: '#F86C6B',
    white: '#FFFFFF',
    yellow: '#FFC107',
    warning: '#FFC107'
};

export const CenterCol = styled(Col_)`
    text-align: center;
`;

export const FlexCol = styled(Col_)`
    align-items: center;
    display: flex;
`;

export const FormError = styled(Label)`
    color: #F86C6B;
    font-size: 12px;
    font-style: italic;
    margin-bottom: 0px;
    margin-top: 0px;
`;

export const FormLabel = styled(Label)`
    color: grey;
    font-style: italic;
    margin-bottom: 2px;
    margin-top: 5px;
`;

export const FullRow = styled(Row_)`
    margin: ${ ({ margin }) => margin ? margin : '0px' };
    width: 100%;
`;

export const Link = styled.span`
    color: ${ colors.success };
    cursor: pointer;
    text-decoration: underline;
`;

export const Text = styled(Label)`
    align-items: center;
    display: inline-flex;
    font-size: 13px;
    height: 100%;
    margin: ${ ({ margin }) => margin ?? '0px' };
`;
