import styled from 'styled-components';
import { Label } from 'reactstrap';

export const CenteredHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 34%;
`;

export const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;

export const LeftHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding-left: 5px;
    width: 33%;
`;

export const Img = styled.img`
    height: 36px;
    width: 36px;
`;

export const RightHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
    width: 33%;
`;

export const Title = styled(Label)`
    align-items: center;
    color: #4dbd74;
    font-size: 16px;
    margin: 0px 0px 0px 5px;
    @media (max-width: 475px) {
        display: none;
    }
`;
