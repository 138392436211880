import React from 'react'
import Loadable from 'react-loadable'
import DefaultLayout from './containers/DefaultLayout'
import { LoadingSpinner } from './components'

function Loading() {
  return <LoadingSpinner />
}

const About = Loadable({
  loader: () => import('./views/About/About'),
  loading: Loading,
});

const Calendar = Loadable({
  loader: () => import('./views/Calendar/Calendar'),
  loading: Loading,
});

const Configuration = Loadable({
  loader: () => import('./views/Configuration/Configuration'),
  loading: Loading,
})

const Contact = Loadable({
  loader: () => import('./views/Contact/Contact'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard/Dashboard'),
  loading: Loading,
});

const General = Loadable({
  loader: () => import('./views/General/General'),
  loading: Loading,
});

const Visits = Loadable({
  loader: () => import('./views/HistoricVisits/HistoricVisits'),
  loading: Loading,
})

const Patients = Loadable({
  loader: () => import('./views/Patients/Patients'),
  loading: Loading,
});

const PatientsEdit = Loadable({
  loader: () => import('./views/PatientsEdit/PatientsEdit'),
  loading: Loading,
});

const Payments = Loadable({
  loader: () => import('./views/Payments/Payments'),
  loading: Loading,
});

const Stadistics = Loadable({
  loader: () => import('./views/Stadistics/Stadistics'),
  loading: Loading,
});

const Survey = Loadable({
  loader: () => import('./views/Survey/Survey'),
  loading: Loading,
});

const SurveyTutorial = Loadable({
  loader: () => import('./views/SurveysEdit/SurveyTutorial'),
  loading: Loading,
});

const Surveys = Loadable({
  loader: () => import('./views/Surveys/Surveys'),
  loading: Loading,
});

const SurveysEdit = Loadable({
  loader: () => import('./views/SurveysEdit/SurveysEdit'),
  loading: Loading,
});

const Visit = Loadable({
  loader: () => import('./views/Visit/Visit'),
  loading: Loading,
});

const WhatsAppTemplates = Loadable({
  loader: () => import('./views/WhatsAppTemplates/WhatsAppTemplates'),
  loading: Loading,
});

const routes = [
  { path: '/', exact: true, name: 'Inicio', component: DefaultLayout },
  { path: '/principal', exact: true, name: 'Principal', component: Dashboard },

  { path: '/calendario', exact: true, name: 'Calendario', component: Calendar },

  { path: '/configuracion', exact: true, name: 'Configuración', component: Configuration },

  { path: '/pacientes', exact: true, name: 'Pacientes', component: Patients },
  { path: '/pacientes/nuevo', exact: true, name: 'Nuevo', component: PatientsEdit },
  { path: '/pacientes/editar', exact: true, name: 'Editar', component: PatientsEdit },
  { path: '/pacientes/general', exact: true, name: 'Análisis', component: General },
  { path: '/pacientes/general/cuestionario', exact: true, name: 'Cuestionario', component: Survey },
  { path: '/pacientes/general/visita', exact: true, name: 'Visita', component: Visit },

  { path: '/cuestionarios', exact: true, name: 'Cuestionarios', component: Surveys },
  { path: '/cuestionarios/nuevo', exact: true, name: 'Nuevo', component: SurveysEdit },
  { path: '/cuestionarios/editar', exact: true, name: 'Editar', component: SurveysEdit },
  { path: '/cuestionarios/tutorial', exact: true, name: 'Tutorial', component: SurveyTutorial },

  { path: '/plantillas', exact: true, name: 'Plantillas', component: WhatsAppTemplates },

  { path: '/historial/visitas', exact:true, name:'Visitas', component: Visits},

  { path: '/pagos', exact: true, name: 'Pagos', component: Payments},

  { path: '/estadisticas', exact: true, name: 'Estadísticas', component: Stadistics},

  { path: '/info', exact: true, name: 'Acerca de', component: About },

  { path: '/contacto', exact: true, name: 'Contacto', component: Contact },

];

export default routes;
