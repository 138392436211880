import React, { Component } from 'react';
import { TextMask, InputAdapter } from 'react-text-mask-hoc';


class TimePicker extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    //Props
    //  value: HH:mm
    //  onChange: function()
    //  name: string

    onChange(event) {
        let value = event.target.value;
        if (value.substr(0, 2) > 23) {
            value = 23
        }
        event.target.value = value;
        this.props.onChange(event);
    }

    render() {
        return (
            <TextMask
                style={{ minWidth: 75 }}
                mask={[/[0-2]/, /\d/, ':', /[0-5]/, /\d/]}
                Component={InputAdapter}
                className="form-control"
                value={this.props.value}
                onChange={this.onChange}
                name={this.props.name}
                placeholder='00:00'
            />
        );
    }
}

export default TimePicker;