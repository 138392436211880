import {
    axiosDelete,
    axiosGet,
    axiosPost,
    axiosPut
} from 'core/axiosInstance';


export const activatePatient = async id => {
    try {
        await axiosPut(`patient_activate/${ id }`);
        return true;
    } catch (err) {
        return false;
    }
}

export const deactivatePatient = async id => {
    try {
        await axiosPut(`patient_deactivate/${ id }`);
        return true;
    } catch (err) {
        return false;
    }
}

export const deletePatient = async id => {
    try {
        await axiosDelete(`patient/${ id }`);
        return true;
    } catch (err) {
        return false;
    }
}

export const deletePatientObjective = async id => {
    try {
        await axiosDelete(`objective/${ id }`);
        return true;
    } catch (err) {
        return false;
    }
}

export const deletePatientGroup = async id => {
    try {
        await axiosDelete(`patient_group/${ id }`);
        return true;
    } catch (err) {
        return false;
    }
}

export const getLastWarnings = async params => {
    try {
        const { data }= await axiosGet('patient_lastWarning', params);
        return data;
    } catch (err) {
        return [];
    }
}

export const getPatient = async id => {
    try {
        const { data }= await axiosGet(`patient/${ id }`);
        const { patient_groups, ...record } = data[0];
        return {
            ...record,
            patient_groups: !!patient_groups ? patient_groups.split(',').map(elem => Number(elem)) : []
        };
    } catch (err) {
        return {};
    }
}

export const getPatientGroups = async () => {
    try {
        const { data }= await axiosGet('patient_group');
        return data;
    } catch (err) {
        return [];
    }
}

export const getPatientNames = async () => {
    try {
        const { data }= await axiosGet('patient_names');
        return data;
    } catch (err) {
        return [];
    }
}

export const getPatientObjectives = async id => {
    try {
        const { data }= await axiosGet(`objective/${ id }`);
        return data;
    } catch (err) {
        return [];
    }
}

export const getPatientRotation = async id => {
    try {
        const { data }= await axiosGet(`patient_rotation/${ id }`);
        return data[0];
    } catch (err) {
        return [];
    }
}

export const getPatients = async (params = {}) => {
    try {
        const { data } = await axiosGet('patient', params);
        return data;
    } catch (err) {
        return [];
    }
}

export const postPatient = async body => {
    try {
        const { data: { insertId } } = await axiosPost('patient', body);
        return insertId;
    } catch (err) {
        return false;
    }
}

export const postPatientGroup = async body => {
    try {
        const { data: { insertId } } = await axiosPost('patient_group', body);
        return insertId;
    } catch (err) {
        return false;
    }
}

export const postPatientObjective = async body => {
    try {
        const { data: { insertId } } = await axiosPost('objective', body);
        return insertId;
    } catch (err) {
        return false;
    }
}

export const putPatient = async (id, body) => {
    try {
        await axiosPut(`patient/${ id }`, body);
        return true;
    } catch (err) {
        return false;
    }
}

export const putPatientSimple = async (id, body) => {
    try {
        await axiosPut(`patient_simple/${ id }`, body);
        return true;
    } catch (err) {
        return false;
    }
}

export const putPatientObjective = async (id, body) => {
    try {
        await axiosPut(`objective/${ id }`, body);
        return true;
    } catch (err) {
        return false;
    }
}

export const putPatientRotation = async id => {
    try {
        await axiosPut(`patient_rotation/${ id }`);
        return true;
    } catch (err) {
        return false;
    }
}
