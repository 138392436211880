import { Input } from 'reactstrap';
import { prefixes } from '../common/phones';
import React, { Component, Fragment } from 'react';
import { func, string } from 'prop-types';

class DialPicker extends Component {
    static propTypes = {
        name: string,
        onChange: func,
        selected: string,
        size: string
    }

    _handleChange = ({ target: { value } }) => {
        const { onChange } = this.props;
        const country = prefixes.find(elem => elem.code === value);
        onChange(country);
    }

    render() {
        const { name, selected, size } = this.props;
        return (
            <Fragment>
                <Input type='select' bsSize={size || 'md'} name={ name } value={ selected || 'AR' } onChange={ this._handleChange } >
                    {
                        prefixes.map(({ code, dialCode, name }) =>
                            <option key={ code } value={ code }>{ `${ name } (${ dialCode })` }</option>
                        )
                    }
                </Input>
            </Fragment>

        );
    }
}

export default DialPicker;