import { colors } from 'styled';
import styled from 'styled-components';

const { darkGreen, gray, warning } = colors;

export const Container = styled.div`
    background-color: white;
    height: 100%;
    overflow-y: auto;
    padding: 5px 10px;
    width: 100%;
`;

export const ContextualInfo = styled.div`
`;

export const Item = styled.div `
    color: ${ warning };
    font-style: italic;
    font-weight: 500;
    margin-top: 5px;
    text-decoration: underline;
`;

export const Separator = styled.hr`
    border-top: 1px solid ${ darkGreen };
    box-shadow: 0px 0px 1px 0px ${ gray };
    margin: 5px 15px;
    ${ ({ marginTop }) => marginTop ? `margin-top: ${ marginTop };` : '' }
`;

export const Subtitle = styled.div`
    color: ${ darkGreen };
    font-size: 14px;
    font-weight: 400;
    padding-top: 5px;
    text-align: center;
`;

export const Text = styled.div`
    font-size: 13px;
    margin-top: 10px;
    text-align: ${ ({ textAlign }) => textAlign || 'left' };
`;

export const Title = styled.div`
    color: ${ darkGreen };
    font-size: 15px;
    font-weight: 500;
    padding-top: 5px;
    text-align: center;
`;
