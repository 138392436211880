import { getPaymentPlans } from 'core/Payment/services';
import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { bool, func, number, string } from 'prop-types';

class PaymentPlanPicker extends Component {
    static propTypes = {
        name: string,
        onChange: func ,
        placeholder: string,
        selected: number,
        selectNone: bool,
        size: string
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            paymentPlans: []
        }
    }

    async componentDidMount(){
        try{
            this.setState({
                loading: true
            });
            const paymentPlans = await getPaymentPlans();
            this.setState({
                loading: false,
                paymentPlans
            });
        } catch (err) {
            this.setState({
                loading: false
            });
        }
    }

    onChange = event => {
        const { paymentPlans } = this.state;
        const { onChange } = this.props;
        const { target: { value } } = event;
        const paymentPlan = paymentPlans.find( (elem) => elem.id === parseInt(value, 10));
        onChange(event, paymentPlan);
    }

    render() {
        const { name, placeholder, selected, selectNone, size } = this.props;
        const { loading, paymentPlans } = this.state;
        return (
            <Input disabled={ loading || paymentPlans.length === 0 } type='select' bsSize={ size || 'md' } 
                name={ name } value={ selected } onChange={ (event) => this.onChange(event) } >
                {
                    ((selected === 0) || selectNone) && !loading && paymentPlans.length !== 0 &&
                    <option value={0}>{ placeholder || 'Seleccioná un convenio...' }</option>
                }
                {
                    loading &&
                    <option value={ 0 }>Cargando convenios...</option>
                }
                {
                    paymentPlans.length === 0 &&
                    <option value={0}>No hay convenios disponibles.</option>
                }
                {
                    paymentPlans.map( ({ id, name }) => 
                        <option key={ id } value={ id }>{ name }</option>
                    )
                }
            </Input>
        );
    }
}

export default PaymentPlanPicker;