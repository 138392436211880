import cookie from 'react-cookies';
import axios from 'axios';
import moment from 'moment';
import {
    isEmpty,
    keys,
    some
} from 'lodash';
const config = require('config');

export const addArray = array => array.reduce((ac, cur) => parseFloat(ac) + parseFloat(cur));

export const calcSomatotipo = ({
    ant_abdominal,
    ant_arm_flexed,
    ant_calf,
    ant_calf_max,
    ant_biceps,
    ant_femoral,
    ant_hip,
    ant_humeral,
    ant_iliac_crest,
    ant_thigh,
    ant_triceps,
    ant_subscapular,
    ant_supraspinal,
    ant_waist,
    height,
    weight
}) => {
    const ICC = (!!ant_hip && !!ant_waist) ? (ant_waist/ant_hip) : null;
    const sum4 = addArray([ant_triceps, ant_subscapular, ant_biceps, ant_iliac_crest]);
    const sum6 = addArray([ant_abdominal, ant_calf, ant_thigh, ant_triceps, ant_subscapular, ant_supraspinal]);
    const IMC = (!!height && !!weight) ? (Number(weight) / Math.pow(Number(height), 2)) : null;
    const foldSumatory = addArray([ant_triceps, ant_subscapular, ant_supraspinal]);
    const fixedFoldSumatory = !!height ? ((170.18 / (height * 100)) * foldSumatory) : 0;
    const endomorphism = fixedFoldSumatory ? -0.7182 + 0.1451 * fixedFoldSumatory - 0.00068 * Math.pow(fixedFoldSumatory, 2) + 0.0000014 * Math.pow(fixedFoldSumatory, 3) : 0;
    const mesomorphism = ant_humeral && ant_femoral && ant_arm_flexed && ant_triceps && ant_calf && ant_calf_max && height
        ? 0.858 * ant_humeral + 0.601 * ant_femoral + 0.188 * (ant_arm_flexed - ant_triceps/10) + 0.161 * (ant_calf_max - ant_calf/10) - height * 100 * 0.131 + 4.5
        : 0;
    const CAP = height && weight ? (height * 100) / Math.pow(weight, 1 / 3) : 0;
    const ectomorphism = !CAP ? 0 : CAP >= 40.75 ? CAP * 0.732 - 28.58 : CAP >= 38.25 ? CAP * 0.463 - 17.68 : 0.1;
    return ({
        ICC,
        IMC,
        ectomorphism,
        endomorphism,
        mesomorphism,
        sum4,
        sum6
    });
};

export const empty = object => some(object, isEmpty);

export function getErrorsFromValidationError(validationError) {
    const FIRST_ERROR = 0
    return validationError.inner.reduce((errors, error) => {
        return {
            ...errors,
            [error.path]: error.errors[FIRST_ERROR],
            }
    }, {})
}

const goToLogin = function (component) {
    component.props.history.push({
        pathname: '/login',
        search: `?next=${ component.props.location.pathname.substr(1) }`
    })
};

export function loginOnFail(component, err) {
    try {
        if (err.response.status === 500) {
            goToLogin(component)
        }
    } catch (error) {
        
    }
};

export const parseAge = borndate => {
    if (moment(borndate).isValid()) {
        const years = moment().diff(borndate, 'years');
        if (years >= 1) {
            return `${ years } ${ years === 1 ? 'año' : 'años' }`
        } else {
            const months = moment().diff(borndate, 'months');
            return `${ months } ${ months === 1 ? 'mes' : 'meses' }`;
        }
    } else {
        return 'Fecha inválida';
    }
};

export function print(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
};

export function sendWhatsApp(code, cellphone, message) {
    window.open(
        `https://api.whatsapp.com/send?l=es&phone=${ code }${ cellphone }${ message === '' ? '' : `&text=${ encodeURI(message)}` }`,
        `Compartir en WhatsApp ${ moment().format('YYYYMMDDHHmmSS') }`
    );
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export function validate(getValidationSchema) {
    return (values) => {
        const validationSchema = getValidationSchema(values)
        try {
            validationSchema.validateSync(values, {
                abortEarly: false
            })
            return {}
        } catch (error) {
            return getErrorsFromValidationError(error)
        }
    }
};

const validateKey = (data, key, validations = []) => {
    if (validations.includes('required') && !data[key]) {
        return {
            [key]: 'Requerido'
        };
    } else {
        return {};
    }
};

export const validateData = (data, fixture) => {
    const errors = keys(fixture).map(key => validateKey(data, key, fixture[key])).reduce((acc, curr) => ({ ...acc, ...curr }), {});
    return {
        errors,
        isValid: isEmpty(errors)
    };
};

export async function verifySession(component) {
    let token = cookie.load('nutricion-auth');
    if (token) {
        localStorage.setItem('nutricion-auth', token);
    } else {
        token = localStorage.getItem('nutricion-auth')
    }
    if (token) {
        try {
            cookie.save('nutricion-auth', token, {
                path: '/'
            });
            let res = await axios({
                method: 'GET',
                url: config.API + 'auth/verifyMe',
                headers: {
                'x-access-token': cookie.load('nutricion-auth')
                },
            });
            if (!res || res.status !== 200) {
                goToLogin(component)
            } else {
                return true;
            }
        } catch (err) {
            goToLogin(component)
        }
    } else {
        goToLogin(component);
    }
};
