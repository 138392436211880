import { Button, Card, CardBody, Col } from 'reactstrap';
import { CardHeader, Row } from './styled';
import React, { Component } from 'react';
import { any, array, func, string } from 'prop-types';

export default class TabCard extends Component {
    static propTypes = {
        active: string,
        children: any,
        headerWidth: string,
        onChange: func,
        tabs: array
    }

    render() {
        const { active: activeTab, headerWidth, children, onChange, tabs = [] } = this.props;
        return (
            <Card className='card-xs card-md-fit'>
                <CardHeader>
                    <Row minwidth={ headerWidth }>
                        <Col>
                            {
                                tabs.map(tab => {
                                    const active = activeTab === tab;
                                    return <Button
                                        color={ active ? 'success' : 'secondary' }
                                        key={ tab }
                                        size="sm"
                                        style={{ margin: 5 }}
                                        onClick={ !active ? () => onChange(tab) : () => null }>
                                        { tab }
                                    </Button>;
                                })
                            }
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    { children }
                </CardBody>
            </Card>
        )
    }
}
