import {
    axiosDelete,
    axiosGet,
    axiosPost
} from 'core/axiosInstance';

export const deletePaymentPlanRequire = async () => {
    try {
        await axiosDelete('payment_plan_require');
        return true;
    } catch (err) {
        return false;
    }
}

export const getPaymentBenefits = async () => {
    try {
        const { data } = await axiosGet('payment/benefits');
        return data;
    } catch (err) {
        return [];
    }
}

export const getPaymentPlans = async () => {
    try {
        const { data } = await axiosGet('payment_plan');
        return data;
    } catch (err) {
        return [];
    }
}

export const getPayments = async () => {
    try {
        const { data } = await axiosGet('payment');
        return data;
    } catch (err) {
        return [];
    }
}

export const postPaymentPlanRequire = async body => {
    try {
        await axiosPost('payment_plan_require', body);
        return true;
    } catch (err) {
        return false;
    }
}

export const postPaypal = async body => {
    try {
        const { data } = await axiosPost('payment-paypal', body);
        return data;
    } catch (err) {
        return [];
    }
}

export const requireMP = async params => {
    try {
        const { data } = await axiosGet('payment/require', params);
        return data;
    } catch (err) {
        return [];
    }
}

export const requirePaypal = async params => {
    try {
        const { data } = await axiosGet('payment/require-papal', params);
        return data;
    } catch (err) {
        return [];
    }
}