import React, { Component } from 'react'
import 'react-dates/initialize'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import 'moment/locale/es'
const titleize = require('titleize')

export default class DatePickerOld extends Component {
    constructor(props){
        super(props);
        this.state = {
            focused: false
        }
    }
    render() {
        let { date, onChange, format, disableFocus, placeholder } = this.props
        let { focused } = this.state
        return (
            <SingleDatePicker
                date={date}
                onDateChange={onChange}
                focused={ disableFocus ? false : focused }
                onFocusChange={(focus) => this.setState({ focused: focus.focused })}
                id='date_input'
                displayFormat={ format || 'DD/MM/YYYY' }
                small
                numberOfMonths={1}
                hideKeyboardShortcutsPanel
                isOutsideRange={() => false}
                placeholder={ placeholder || ''}
                renderMonthText={(day) => titleize(moment(day).format('MMMM YYYY'))}
            />
        )
    }
}
