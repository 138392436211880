import { Button } from 'reactstrap';
import React from 'react';
import { bool, func, string } from 'prop-types';

const CheckboxButton = ({
    checked,
    children,
    color,
    onClick,
    size
}) => (
    <Button className='btn-pill' size={size || 'sm'} color={!checked ? 'secondary' : color ? color : 'success'} onClick={onClick}>
        { !checked ? <i className="fa fa-square-o" /> : <i className="fa fa-check-square-o" /> }{ !!children && ` ${ children }` }
    </Button>
);

CheckboxButton.propTypes = {
    checked: bool,
    children: string,
    color: string,
    onClick: func,
    size: string
};

export default CheckboxButton;
