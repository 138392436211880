import { DropdownToggle as DropdownToggle_ } from 'reactstrap';
import styled from 'styled-components';

export const AppSideContainer = styled.div`
    display: inline-flex;
`;

export const Container = styled.div`
    display: flex;
    right: 0px;
`;

export const DropdownContainer = styled.div`
    display: inline-flex;
`;

export const DropdownToggle = styled(DropdownToggle_)`
    @media (min-width: 476px) {
        min-width: 85px;
    }
`;