import React, { Component, Fragment } from 'react';
import { Input } from 'reactstrap';


class QuestionSizePicker extends Component {
    /*constructor(props) {
        super(props);
    }*/

    //Props
    // name : string
    // selected : string
    // onChange : function()

    // size : string

    render() {
        return (
            <Fragment>
                <Input type='select' bsSize={this.props.size || 'md'} name={this.props.name || 'size'} value={this.props.selected} onChange={this.props.onChange}>
                    <option value={12}>Larga</option>
                    <option value={8}>Media</option>
                    <option value={4}>Corta</option>
                    <option value={2}>Mini</option>
                </Input>
            </Fragment>

        );
    }
}

export default QuestionSizePicker;