import { ModalBody } from './styled';
import React from 'react';
import { Modal as ModalComponent, ModalHeader } from 'reactstrap';
import { any, bool, func, string } from 'prop-types';

const Modal = ({
    active,
    children,
    color,
    overflowx,
    title = '',
    toggle = () => null
}) => {

    return (
        <ModalComponent className={ `modal-lg modal-${ color ?? 'success' }` } isOpen={ active } toggle={ toggle }>
            <ModalHeader toggle={ toggle }>
                { title }
            </ModalHeader>
            <ModalBody overflowx={ overflowx }>
                { children }
            </ModalBody>
        </ModalComponent>
    )
}

Modal.propTypes = {
    active: bool,
    children: any,
    color: string,
    overflowx: string,
    title: string,
    toggle: func
}

export default Modal
