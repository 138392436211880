import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { any, bool, func, string } from 'prop-types';


class ConfirmButton extends Component {
    static propTypes = {
        autoFocus: bool,
        children: any,
        color: string,
        Body: any,
        Footer: any,
        Header: any,
        message: string,
        onAccept: func,
        onAcceptText: string,
        onCancelText: string,
        showToggle: bool,
        shown: bool,
        size: string,
        text: string,
        toogle: func,
    }

    render() {
        const { Body, Footer, Header, autoFocus, children, color, isOpen, showToggle = false, size, toggle } = this.props;
        return (
            <Modal autoFocus={autoFocus || false} isOpen={ isOpen } toggle={toggle} className={`modal-${ size || 'sm'} modal-${color || 'primary'}`}>
                {
                    !!Header &&
                    <ModalHeader toggle={ !!showToggle ? toggle : null }>
                        { Header }
                    </ModalHeader>
                }
                {
                    !!Body
                        ? <ModalBody>{ Body }</ModalBody>
                        : !!children
                            ? <ModalBody>{ children }</ModalBody>
                            : null
                }
                {
                    !!Footer &&
                    <ModalFooter>
                        { Footer }
                    </ModalFooter>
                }
            </Modal>
        );
    }
}

export default ConfirmButton;