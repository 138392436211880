import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import '@coreui/icons/css/coreui-icons.min.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './scss/style.css';
import { DefaultLayout } from './containers';
import { Login, Register, Recover, Terms } from './views/Pages';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-138647346-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path="/login" name="Login" component={Login} />
                    <Route exact path="/recuperar" name="Recuperación" component={Recover} />
                    <Route exact path="/registro" name="Registro" component={Register} />
                    <Route exact path="/terminos" name="Terms" component={Terms} />
                    <Route path="/" name="Inicio" component={DefaultLayout} />
                </Switch>
            </HashRouter>
        );
    }
}

export default App;
