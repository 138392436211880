import cookie from 'react-cookies';
import {
    userConfig,
    userObjectives
} from 'userConfig';
import {
    axiosDelete,
    axiosGet,
    axiosPost,
    axiosPut,
    unauthAxiosGet,
    unauthAxiosPost,
    unauthAxiosPut
} from 'core/axiosInstance';

export const deleteNotification = async id => {
    try {
        await axiosDelete(`notification/${ id }`);
        return true;
    } catch (err) {
        return false;
    }
}

export const deleteUser = async body => {
    try {
        await axiosDelete('user', body);
        return true;
    } catch (err) {
        return false;
    }
}

export const getDashboardUser = async () => {
    try {
        const res = await axiosGet('user');
        const data = res.data[0];
        return data;
    } catch (err) {
        return {};
    }
}

export const getLastPayment = async () => {
    try {
        const res = await axiosGet('payment/last');
        return !!res ? res.data : {
            valid: false
        };
    } catch (err) {
        return {
            valid: false
        };
    }
}

export const getLogOut = async () => {
    try {
        await axiosGet('auth/logout');
        cookie.remove('nutricion-auth', {
            path: '/'
        });
        localStorage.removeItem('nutricion-auth');
        return true
    } catch (err) {
        return false;
    }
}

export const getNotifications = async () => {
    try {
        const { data } = await axiosGet('notification');
        return data;
    } catch (err) {
        return [];
    }
}

export const getReactivateUser = async params => {
    try {
        await unauthAxiosGet('auth/reactivate', params);
        return true;
    } catch (err) {
        return false;
    }
}

export const getRecoverPassword = async params => {
    try {
        await unauthAxiosGet('auth/recover', params);
        return true;
    } catch (err) {
        return false;
    }
}

export const getUser = async () => {
    try {
        const res = await axiosGet('user/config');
        const data = res.data[0];
        return {
            ...data,
            config: { ...userConfig, ...(data.config ? JSON.parse(data.config) : {}) },
            objectives: { ...userObjectives, ...(data.objectives ? JSON.parse(data.objectives) : {}) }
        };
    } catch (err) {
        return {
            config: userConfig,
            objectives: userObjectives
        };
    }
}

export const getUserPlan = async () => {
    try {
        const { data } = await axiosGet('user/plan');
        return data;
    } catch (err) {
        return [];
    }
}

export const getVerify = async () => {
    try {
        const res = await axiosGet('auth/verifyMe');
        return res;
    } catch (err) {
        return {
        status: 401
        };
    }
}

export const postLogin = async body => {
    try {
        const { data } = await unauthAxiosPost('auth/login', body);
        return data;
    } catch (err) {
        throw err;
    }
}

export const postRegisterUser = async body => {
    try {
        const { data } = await unauthAxiosPost('auth/register', body);
        return data;
    } catch (err) {
        throw err;
    }
}

export const putActivateUser = async body => {
    try {
        await unauthAxiosPut('auth/activate', body);
        return true
    } catch (err) {
        return false;
    }
}

export const putChangePassword = async body => {
    try {
        const { data } = await unauthAxiosPut('auth/password', body);
        return data;
    } catch (err) {
        throw err;
    }
}

export const putUser = async body => {
    try {
        await axiosPut('user/config', body);
        return true
    } catch (err) {
        return false;
    }
}

export const sendMessage = async body => {
    try {
        await axiosPost('user/send_message', body);
        return true;
    } catch (err) {
        return false;
    }
}
