import { AsideBar } from 'containers';
import { Container, Label } from 'reactstrap';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
    AppAside,
    AppBreadcrumb,
    //AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    //AppSidebarMinimizer,
    AppSidebarNav
} from '@coreui/react';
import navigation from '../../_nav';
import routes from '../../routes';
import DefaultHeader from './DefaultHeader';

import { verifySession } from '../../common/functions';

class DefaultLayout extends Component {
    constructor(props) {
        super();
        this.state = {
            logged: false
        }
    }

    async componentDidMount() {
        let logged = await verifySession(this)
        this.setState({
            logged
        })
    }

    render() {
        const { logged } = this.state;
        if (logged){
            return (
                <div className='app'>
                    <AppHeader fixed style={{ backgroundColor:'#edf8f1', minWidth: 360 }}>
                        <DefaultHeader />
                    </AppHeader>
                    <div className='app-body'>
                        <AppSidebar fixed display='lg'>
                            <AppSidebarHeader />
                            <AppSidebarForm />
                            <AppSidebarNav navConfig={ navigation } {...this.props} />
                            <AppSidebarFooter style={{ height: 60 }}>
                                <div className="sidebar-footer-divider" />
                                <div className="sidebar-footer-container">
                                    ¡Seguinos en nuestras redes!<br />
                                    <Label className="sidebar-footer-link" onClick={ () => window.open("https://www.instagram.com/bamboo.nutricion") } >
                                        Instagram
                                    </Label>
                                    { ` - ` }
                                    <Label className="sidebar-footer-link" onClick={ () => window.open("https://www.facebook.com/bamboo.nutricion") } >
                                        Facebook
                                    </Label>                    
                                </div>
                            </AppSidebarFooter>
                        </AppSidebar>
                        <main className='main main-print'>
                            <Container className="breadcrumb-container d-print-none" style={{ fontSize: 11, padding: 0, width: '100%' }}>
                                <AppBreadcrumb appRoutes={routes}/>
                            </Container>
                            <Container fluid>
                                <Switch>
                                    {routes.map((route, idx) => {
                                        return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                                            <route.component {...props} />
                                        )} />)
                                            : (null);
                                    },
                                    )}
                                    <Redirect from='/' to='/principal' />
                                </Switch>
                            </Container>
                        </main>
                        <AppAside fixed>
                            <AsideBar />
                        </AppAside>
                    </div>
                </div>
            );
        } else{
            return <div/>
        }
    }
}

export default DefaultLayout;
