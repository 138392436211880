import { ModalWrapper } from 'components';
import { getUser } from 'core/User/services';
import { getWhatsappTemplate } from 'core/WhatsAppTemplates/services';
import { sendWhatsApp } from 'common/functions';
import { Button, Container, Label, Row } from 'reactstrap';
import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';

export default class SendWhatsApp extends Component {
    static propTypes = {
        isOpen: bool,
        patient: object,
        toggle: func
    }

    constructor (props) {
        super(props);
        this.state = {
            loading: false,
            templates: [],
            user: {}
        }
    }

    componentDidMount = async () => {
        this.setState({
            loading: true
        });
        const [templates, user] = [
            await getWhatsappTemplate(),
            await getUser()
        ];
        templates.unshift({ message: '', name: 'Mensaje en blanco' });
        this.setState({
            loading: false,
            templates,
            user
        })
    }

    send = message => {
        const { user: { config: { dialCode }, name: personalName, professional_name: professionalName } } = this.state;
        const { patient: { cellphone, name, nickname, surname, visitString } } = this.props;
        const newMessage = message
            .replace(/<NOMBRE>/gi, name)
            .replace(/<APELLIDO>/gi, surname)
            .replace(/<APODO>/gi, nickname ?? name)
            .replace(/<ULTTURN>/gi, visitString)
            .replace(/<PROFESIONAL>/gi, professionalName)
            .replace(/<NOMBREPROPIO>/gi, personalName);
        sendWhatsApp(dialCode, cellphone, newMessage);
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { templates } = this.state;
        return (
            <ModalWrapper
                isOpen={ isOpen }
                size="md"
                toggle={ toggle }
                Body={
                    <Container>
                        <Row>
                            <Label>Elegí el mensaje que querés enviarle a tu paciente</Label><br />
                            <Label className="label-muted">Podés editar las plantillas desde la sección "Plantillas" del menú.</Label>
                        </Row>
                        {
                            templates.map(({ message, name }, index) =>
                                <Row key={ index } style={{ textAlign: 'center' }}>
                                    <Button className="success" color="link" size="sm" onClick={ () => this.send(message) }>
                                        { name }
                                    </Button>
                                </Row>
                            )
                        }
                    </Container>
                }
                Footer={
                    <Button color="secondary" onClick={ toggle }>
                        Volver
                    </Button>
                }
            />
        )
    }
}
